import { CssBaseline, createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license";
import { Navigate, Outlet, Route, Routes } from "react-router";
import { setUpAxios } from "../shared/api/axiosHelper";
import LoggedOut from "../shared/auth/LoggedOut";
import SignUp from "../shared/auth/SignUp";
import Authentication from "../shared/auth/b2c/Authentication";
import LogoutContainer from "../shared/auth/b2c/LogoutContainer";
import { handleB2CRedirectUrlState } from "../shared/auth/b2c/redirects";
import Error from "../shared/components/Error";
import { Licenses } from "../shared/licensing";
import { initAppInsights } from "../shared/logging";
import { lightThemeOptions } from "../shared/theme";
import App, { defaultClientParam } from "./App";
import { redirectToB2CAuthWithImpersonation } from "./impersonation/impersonationAuth";
import { handlePortalImpersonation } from "./impersonation/impersonationHandler";
import { setAuthMode } from "./variables";

LicenseInfo.setLicenseKey(Licenses.MUI_X_PREMIUM_KEY);

const handled = handleB2CRedirectUrlState(() => setAuthMode("end_user"));

if (!handled) {
  initAppInsights({ appId: "InvestorPortal" });
  handlePortalImpersonation();
  setUpAxios(redirectToB2CAuthWithImpersonation);
}

const Entry = () => {
  if (handled) {
    return <></>;
  }

  return (
    <ThemeProvider theme={createTheme(lightThemeOptions)}>
      <CssBaseline />
      <Routes>
        <Route path="/oauth" element={<Authentication />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/:client/*" element={<Outlet />}>
          <Route path="loggedout" element={<LoggedOut href="./" />} />
          <Route path="loggedoutImpersonation" element={<LoggedOut hideLoginRedirectButton={true} href="./" />} />
          <Route path="logout" element={<LogoutContainer />} />
          <Route path="*" element={<App />} />
        </Route>
        <Route path="/error" element={<Error />} />
        <Route path="" element={<Navigate to={`/${defaultClientParam}`} />} />
      </Routes>
    </ThemeProvider>
  );
};

export default Entry;
