import ArrowBackIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowFwdIcon from "@mui/icons-material/ArrowForwardRounded";
import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Box, Button, Step, StepLabel, Stepper } from "@mui/material";
import { useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { api } from "../../../../../api/client";
import { useClientContext } from "../../../../../contexts/ClientContext";
import { workspaceInvestorSelector } from "../../../../../store/state/user/selectors";
import LpSettingsWrapper from "../../LpSettingsWrapper";
import { useBankSettingsNavigation } from "../navigation";
import FundAssignmentsForm from "./FundAssignmentsForm";
import LeavePageConfirmationDialog from "./LeavePageConfirmationDialog";
import NewBankAccountForm from "./NewBankAccountForm";
import NewBankAccountReview from "./NewBankAccountReview";
import { StateStep, getInitialState, reducer, stateToCreateInvestorBankAccountRequest } from "./newBankAccountState";

const createBankAccount = withErrorHandling(api.bankAccounts.createBankAccount);

const stepLabels: [StateStep, string][] = [
  [StateStep.BankAccount, "Bank Account"],
  [StateStep.FundAssignments, "Fund Assignments"],
  [StateStep.Review, "Review"],
];

const NewBankAccountPage = () => {
  const { clientCode } = useClientContext();
  const { newBankAccountPageNavigationState: navigationState, navigateToBankAccountsPage } =
    useBankSettingsNavigation();

  const { sendNotification, sendNotificationError } = useNotificationContext();
  const workspaceInvestor = useSelector(workspaceInvestorSelector);

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(workspaceInvestor?.fundInvestors ?? [], navigationState?.availableFundInvestorIds ?? [])
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!navigationState) {
    return <Navigate to={`/${clientCode}/settings/lp/bank`} />;
  }

  if (!state.fundAssignments.some((assignment) => !assignment.disabled)) {
    return (
      <Alert severity="warning">
        <AlertTitle>No funds available for assignments</AlertTitle>
        <Button variant="text" onClick={() => navigateToBankAccountsPage()}>
          Go Back
        </Button>
      </Alert>
    );
  }

  const handleBackButtonClick = () => {
    setShowCancelConfirmation(true);
  };

  const handleConfirmLeave = () => {
    setShowCancelConfirmation(false);
    navigateToBankAccountsPage();
  };

  const handleCancelLeave = () => {
    setShowCancelConfirmation(false);
  };

  const handlePreviousStep = () => {
    dispatch({ type: "previous_step" });
  };

  const handleNextStep = async () => {
    if (state.step !== StateStep.Review) {
      dispatch({ type: "next_step" });
      return;
    }

    if (!workspaceInvestor?.investorId) {
      return;
    }

    setIsSubmitting(true);
    const payload = stateToCreateInvestorBankAccountRequest(state);
    const [, error] = await createBankAccount(workspaceInvestor.investorId, payload);
    setIsSubmitting(false);
    if (error) {
      sendNotificationError("Failed to submit the request");
      return;
    }

    sendNotification("The request has been submitted");
    navigateToBankAccountsPage();
  };

  const nextStepLabel = stepLabels[state.step + 1]?.[1] ?? "Send Request";

  return (
    <LpSettingsWrapper
      title="New Bank Account"
      subtitle={workspaceInvestor?.investorTitle}
      onBackButtonClick={handleBackButtonClick}
      Subheader={
        <Box py={1.5} px={3} display="flex" justifyContent="center">
          <Stepper activeStep={state.step}>
            {stepLabels.map(([index, label]) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      }
      Footer={
        <Box py={2} px={3} display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<ArrowBackIcon />}
            onClick={handlePreviousStep}
            sx={{ visibility: state.step === StateStep.BankAccount ? "hidden" : "visible" }}
          >
            Previous
          </Button>
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            endIcon={<ArrowFwdIcon />}
            onClick={handleNextStep}
            disabled={state.isNextStepDisabled}
          >
            {nextStepLabel}
          </LoadingButton>
        </Box>
      }
    >
      {state.step === StateStep.BankAccount && <NewBankAccountForm state={state} dispatch={dispatch} />}
      {state.step === StateStep.FundAssignments && <FundAssignmentsForm state={state} dispatch={dispatch} />}
      {state.step === StateStep.Review && <NewBankAccountReview state={state} />}
      <LeavePageConfirmationDialog
        open={showCancelConfirmation}
        onConfirm={handleConfirmLeave}
        onCancel={handleCancelLeave}
      />
    </LpSettingsWrapper>
  );
};

export default NewBankAccountPage;
