import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, IconButton, List, ListItem, Typography, useTheme } from "@mui/material";
import { parseISO } from "date-fns";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import CircleIcon from "../../../../../shared/icons/CircleIcon";
import { useLocalization } from "../../../../hooks/useLocalization";
import { resetBalanceFilters } from "../../../../store/state/dashboard/dashboardSlice";
import {
  selectDashboardBalanceFilters,
  selectDashboardBalanceFiltersApplied,
  selectDashboardLoading,
} from "../../../../store/state/dashboard/selectors";
import { fetchBalance } from "../../../../store/state/dashboard/thunk";
import { clientCodeSelector } from "../../../../store/state/user/selectors";
import { AppDispatch } from "../../../../store/store";
import ModalPage from "../../../common/ModalPage";
import NoDataResult from "../../common/NoDataResult";
import BalanceCard from "./BalanceCard";
import BalanceFiltersDrawer from "./BalanceFiltersDrawer";
import EffectiveReportingDate from "./EffectiveReportingDate";
import { BalanceCardData } from "./types";

type BalanceMobilePageProps = {
  cardsData: BalanceCardData[];
  reportingDate?: Date;
  onClose: () => void;
};

const BalanceMobilePage = ({ onClose, reportingDate, cardsData }: BalanceMobilePageProps) => {
  const dispatch: AppDispatch = useDispatch();
  const theme = useTheme();
  const [showFilters, setShowFilters] = useState(false);
  const filtersApplied = useSelector(selectDashboardBalanceFiltersApplied);
  const filters = useSelector(selectDashboardBalanceFilters);
  const loading = useSelector(selectDashboardLoading);
  const balanceLocale = useLocalization().dashboard.balance;
  const client = useSelector(clientCodeSelector);

  const handleBack = () => {
    dispatch(resetBalanceFilters());
    const reportingDate = filters.initialDateIso !== undefined ? parseISO(filters.initialDateIso) : undefined;
    dispatch(fetchBalance({ reportingDate, client }));
    onClose();
  };

  const cardsFilteredByInvestorAndFund = cardsData.filter((card) => {
    if (filters.investors.length > 0 && !filters.investors.includes(card.investorName)) {
      return false;
    }
    if (filters.funds.length > 0 && !filters.funds.includes(card.fundName)) {
      return false;
    }
    return true;
  });

  return (
    <ModalPage
      open
      sxContent={{ py: 2 }}
      toolbarChildren={
        <>
          <IconButton edge="start" disabled={loading} onClick={handleBack}>
            <ArrowBackRoundedIcon />
          </IconButton>
          <Typography variant="h6" sx={{ ml: "50%", transform: "translate(-100%, 0)" }}>
            {balanceLocale.widget_name}
          </Typography>

          <IconButton sx={() => ({ ml: "auto", mr: 0 })} edge="end" onClick={() => setShowFilters(!showFilters)}>
            <FilterListIcon />
            {filtersApplied && (
              <Box sx={{ position: "absolute", ml: 3, mb: 4 }}>
                <CircleIcon fill={theme.palette.primary.main} radius={4} />
              </Box>
            )}
          </IconButton>
        </>
      }
    >
      <EffectiveReportingDate reportingDate={reportingDate} dateColor="textPrimary" />

      {loading && (
        <CenteredWrapper zIndex={9999} position="fixed">
          <InlineLoader />
        </CenteredWrapper>
      )}

      {!loading && cardsFilteredByInvestorAndFund.length === 0 && <NoDataResult text={balanceLocale.no_data} />}

      <List sx={{ width: "100%" }}>
        {cardsFilteredByInvestorAndFund.map((bc, i) => (
          <ListItem key={"li" + i} sx={{ px: 0 }}>
            <BalanceCard
              key={"bc" + i}
              investorName={bc.investorName}
              title={bc.fundName}
              date={bc.reportingDate}
              fields={bc.fields}
            />
          </ListItem>
        ))}
      </List>
      <BalanceFiltersDrawer open={showFilters} onClose={() => setShowFilters(false)} />
    </ModalPage>
  );
};

export default BalanceMobilePage;
