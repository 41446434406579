import { Box, Stack, Theme, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useLocalization } from "../../../../hooks/useLocalization";

const NoEmailsComponent = () => {
  const theme = useTheme<Theme>();
  const locale = useLocalization();

  return (
    <Box display="flex" width="100%" flex={1} alignItems="center" justifyContent="center">
      <Stack width="100%" alignItems="center" spacing={2}>
        <svg width="58" height="44" viewBox="0 0 58 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 0.600098V43.5001H57.1V0.600098H0ZM28.5 21.1001L9.9 5.6001H47L28.5 21.1001ZM20.1 20.6001L5 35.1001V7.9001L20.1 20.6001ZM24 23.8001L28.5 27.6001L33 23.8001L48.3 38.4001H8.7L24 23.8001ZM36.9 20.6001L52.1 7.9001V35.1001L36.9 20.6001Z"
            fill={theme.palette.primary.main}
          />
        </svg>
        <Typography color="text.secondary">{locale.inbox.empty_inbox_label}</Typography>
      </Stack>
    </Box>
  );
};

export default NoEmailsComponent;
