import { AccessPermission } from "../../shared/api/types";
import { ClientPermissionSet, InvestorFundPermissionSet } from "../store/state/user/types";

export const getInvestorFunds = (
  permissions: ClientPermissionSet[] | undefined,
  clientCode: string,
  investorId: string
): InvestorFundPermissionSet[] =>
  (permissions ?? [])
    .filter((p) => p.clientCode === clientCode)
    .flatMap((p) =>
      p.investorsPermissions.filter((ip) => ip.investorId === investorId).flatMap((ip) => ip.fundInvestors)
    );

export const getInvestorPermissions = (
  permissions: ClientPermissionSet[] | undefined,
  clientCode: string,
  investorId: string
): AccessPermission[] => {
  const clientPermissions = (permissions ?? []).find((p) => p.clientCode === clientCode);
  if (clientPermissions === undefined) {
    return [];
  }

  const investorPermissions = clientPermissions.investorsPermissions.find((cp) => cp.investorId === investorId);
  return investorPermissions ? investorPermissions.investorLevelPermissions : [];
};

export const hasFundPermission = (
  permissions: ClientPermissionSet[] | undefined,
  clientCode: string,
  investorId: string,
  fundInvestorId: string,
  permission: AccessPermission
) => {
  const investorFunds = getInvestorFunds(permissions, clientCode, investorId);
  const fundInvestor = investorFunds.find((fund) => fund.fundInvestorId === fundInvestorId);
  return !!(fundInvestor && fundInvestor.permissions.includes(permission));
};

export const getCurrentClientFunds = (
  permissions: ClientPermissionSet[] | undefined,
  clientCode: string
): InvestorFundPermissionSet[] =>
  (permissions ?? [])
    .filter((p) => p.clientCode === clientCode)
    .flatMap((p) => p.investorsPermissions.flatMap((ip) => ip.fundInvestors));
