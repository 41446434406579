import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ImpersonationInvestor } from "../../api/types/impersonationTypes";
import ImpersonationInvestorContact from "./ImpersonationInvestorContact";

interface Props {
  investor: ImpersonationInvestor;
  filterText: string;
  contactEmail: string;
  expandedInvestor: string | undefined;
  onExpandInvestor: (investor: string | undefined) => void;
  onContactViewClick: (email: string) => void;
}

const ImpersonationInvestorSection = ({
  investor,
  filterText,
  contactEmail,
  expandedInvestor,
  onExpandInvestor,
  onContactViewClick,
}: Props) => {
  const filterTextVal = filterText.trim().toLowerCase();
  const isSelectedInvestor = investor.title === expandedInvestor;

  return (
    <Accordion
      onChange={(_, newExpanded) => onExpandInvestor(newExpanded ? investor.title : undefined)}
      expanded={isSelectedInvestor || filterTextVal !== ""}
      disableGutters
      elevation={0}
      square
      sx={(t) => ({
        backgroundColor: t.palette.background.grey,
        border: `1px solid ${t.palette.divider}`,
        "&:not(:last-child)": {
          borderBottom: 0,
        },
        "&:before": {
          display: "none",
        },
      })}
    >
      <AccordionSummary
        expandIcon={<ChevronRightIcon />}
        sx={(theme) => ({
          flexDirection: "row-reverse",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
          "& .MuiAccordionSummary-content": {
            marginLeft: theme.spacing(2),
          },
        })}
      >
        <Typography variant="subtitle2">{investor.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {investor.contacts.map((contact) => (
          <ImpersonationInvestorContact
            key={contact.email}
            contact={contact}
            investor={investor}
            isSelectedInvestor={isSelectedInvestor}
            filterText={filterText}
            contactEmail={contactEmail}
            onContactViewClick={onContactViewClick}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default ImpersonationInvestorSection;
