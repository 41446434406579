import { Button, Grid2, Stack, Typography } from "@mui/material";
import { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateRangeSelect from "../../../../shared/components/DateRangeSelect";
import useDebounce from "../../../../shared/hooks/useDebounce";
import {
  anytime,
  DescriptiveDate,
  lastMonth,
  lastQuarter,
  lastYear,
  next30Days,
  nextQuarter,
  nextSevenDays,
  thisMonth,
  thisQuarter,
  thisYear,
  today,
} from "../../../../shared/utilities/dateFilterHelper";
import { useLocalization } from "../../../hooks/useLocalization";
import { resetCashFlowRequest } from "../../../store/state/dashboard/dashboardSlice";
import {
  selectCashFlowFiltersApplied,
  selectCashFlowLoading,
  selectCashFlowRequestData,
} from "../../../store/state/dashboard/selectors";
import { fetchCashFlowData } from "../../../store/state/dashboard/thunk";
import { cashFlowDefaults, DistributionStatus } from "../../../store/state/dashboard/types";
import { financialFundsSelector, financialInvestorsSelector } from "../../../store/state/user/selectors";
import { AppDispatch } from "../../../store/store";
import FundSelectFilter from "../../common/filters/FundSelectFilter";
import InvestorSelectFilter from "../../common/filters/InvestorSelectFilter";
import PaymentStatusFilter from "../../common/filters/PaymentStatusFilter";

interface CashFlowFiltersProps {
  isMobile?: boolean;
}

const CashFlowFilters = ({ isMobile }: CashFlowFiltersProps) => {
  const cashFlowRequest = useSelector(selectCashFlowRequestData);
  const filtersApplied = useSelector(selectCashFlowFiltersApplied);
  const cashFlowsLocale = useLocalization().dashboard.cashFlows;
  const [applyFilters, setApplyFilters] = useState(!isMobile);
  const [selectedInvestors, setSelectedInvestors] = useState<string[]>(cashFlowRequest?.investors || []);
  const [selectedFunds, setSelectedFunds] = useState<string[]>(cashFlowRequest?.funds || []);
  const [selectedDate, setSelectedDate] = useState<DescriptiveDate | undefined>(cashFlowRequest?.descriptiveDate);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(cashFlowRequest?.paymentStatuses || []);
  const cashFlowLading = useSelector(selectCashFlowLoading);
  const investors = useSelector(financialInvestorsSelector("CASH FLOWS"));
  const funds = useSelector(financialFundsSelector("CASH FLOWS"));

  const dispatch: AppDispatch = useDispatch();

  const filterDates = useMemo(
    () => [
      anytime,
      today,
      nextSevenDays,
      next30Days,
      nextQuarter,
      thisMonth,
      thisQuarter,
      thisYear,
      lastMonth,
      lastQuarter,
      lastYear,
    ],
    []
  );

  const cashFlowDataFetch = useDebounce(() => {
    dispatch(
      fetchCashFlowData({
        paging: { page: 0, size: cashFlowDefaults.cashFlowPageSizeAll },
        investors: [...selectedInvestors],
        funds: [...selectedFunds],
        from: selectedDate?.from?.toISOString(),
        to: selectedDate?.to?.toISOString(),
        descriptiveDate: selectedDate,
        paymentStatuses: mapPaymentStatuses(selectedStatuses),
      })
    );
  }, 600);

  useEffect(() => {
    if (applyFilters) {
      cashFlowDataFetch();
    } else {
      setApplyFilters(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    selectedDate,
    selectedDate?.from,
    selectedDate?.to,
    selectedFunds,
    selectedInvestors,
    selectedStatuses,
  ]);

  const clearFilters = () => {
    setSelectedInvestors([]);
    setSelectedFunds([]);
    setSelectedDate(undefined);
    setSelectedStatuses([]);
    dispatch(resetCashFlowRequest());
  };

  const paymentStatuses: Array<[string, DistributionStatus]> = [
    [cashFlowsLocale.payment_status.paid, "Paid"],
    [cashFlowsLocale.payment_status.unpaid, "Unpaid"],
    [cashFlowsLocale.payment_status.partly_paid, "PartlyPaid"],
  ];

  const mapPaymentStatuses = (statuses: string[] | string): DistributionStatus[] => {
    const stringStatuses = [...statuses];
    return paymentStatuses.filter((status) => stringStatuses.includes(status[0])).map((status) => status[1]);
  };

  return (
    <CashFlowFiltersWrapper
      isMobile={isMobile}
      clearFiltersComponent={
        filtersApplied && (
          <Button variant="text" disabled={cashFlowLading} onClick={clearFilters}>
            {cashFlowsLocale.filters.clear_all}
          </Button>
        )
      }
    >
      <Grid2
        container
        width="100%"
        spacing={2}
        columns={isMobile ? 3 : 12}
        sx={(theme) => ({ maxWidth: isMobile ? "auto" : theme.spacing(120) })}
      >
        <Grid2 size={3}>
          <InvestorSelectFilter
            investors={investors}
            disabled={cashFlowLading}
            onSelected={setSelectedInvestors}
            selectedValues={selectedInvestors}
          />
        </Grid2>
        <Grid2 size={3}>
          <FundSelectFilter
            funds={funds}
            disabled={cashFlowLading}
            onSelected={setSelectedFunds}
            selectedValues={selectedFunds}
          />
        </Grid2>
        <Grid2 size={3}>
          <DateRangeSelect
            disabled={cashFlowLading}
            dateRange={selectedDate}
            onChange={setSelectedDate}
            predefinedRanges={filterDates}
            fullWidth={isMobile}
          />
        </Grid2>
        <Grid2 size={3}>
          <PaymentStatusFilter
            disabled={cashFlowLading}
            onSelected={setSelectedStatuses}
            selectedValues={selectedStatuses}
            values={paymentStatuses.map((status) => status[0])}
          />
        </Grid2>
      </Grid2>
    </CashFlowFiltersWrapper>
  );
};

export default CashFlowFilters;

interface CashFlowFiltersWrapperProps {
  isMobile?: boolean;
  clearFiltersComponent?: ReactNode;
}

const CashFlowFiltersWrapper = ({
  children,
  isMobile,
  clearFiltersComponent,
}: PropsWithChildren<CashFlowFiltersWrapperProps>) => {
  const cashFlowsLocale = useLocalization().dashboard.cashFlows;
  return isMobile ? (
    <Stack py={2} px={2}>
      <Stack my={2} direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" color="textPrimary">
          {cashFlowsLocale.filter_title}
        </Typography>
        {clearFiltersComponent}
      </Stack>
      {children}
    </Stack>
  ) : (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={3}>
      {children}
      {clearFiltersComponent}
    </Stack>
  );
};
