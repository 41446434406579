import { initAuthSettings } from "../shared/auth/settings";
import { importEnvVariable } from "../shared/variables";

type AuthMode = "end_user" | "impersonation";

let currentAuthMode: AuthMode = "end_user";

export const getAuthMode = () => currentAuthMode;

export const setAuthMode = (mode: AuthMode) => {
  currentAuthMode = mode;
  initAuthSettings(mode === "impersonation" ? importImpersonationAuthSettings() : importEndUserAuthSettings());
};

const importEndUserAuthSettings = () => ({
  authEndpoint: importEnvVariable("REACT_APP_INVESTOR_PORTAL_AUTH_ENDPOINT"),
  userFlowSignIn: importEnvVariable("REACT_APP_INVESTOR_PORTAL_USER_FLOW_SIGNIN"),
  userFlowSignUp: importEnvVariable("REACT_APP_INVESTOR_PORTAL_USER_FLOW_SIGNUP"),
  userFlowPasswordReset: importEnvVariable("REACT_APP_INVESTOR_PORTAL_USER_FLOW_PASSWORD_RESET"),
  clientId: importEnvVariable("REACT_APP_INVESTOR_PORTAL_CLIENT_ID"),
  portalAccessScope: importEnvVariable("REACT_APP_INVESTOR_PORTAL_ACCESS_SCOPE"),
  authCookieNames: "x-entrilia-ir-authorized",
  refreshCookieNames: "x-entrilia-ir-refresh-available",
});

const importImpersonationAuthSettings = () => ({
  authEndpoint: "",
  userFlowSignIn: "",
  userFlowSignUp: "",
  userFlowPasswordReset: "",
  clientId: importEnvVariable("REACT_APP_ADMIN_PORTAL_CLIENT_ID"),
  portalAccessScope: importEnvVariable("REACT_APP_ADMIN_PORTAL_ACCESS_SCOPE"),
  authCookieNames: "x-entrilia-ap-authorized",
  refreshCookieNames: "x-entrilia-ap-refresh-available",
});
