import ArrowBackIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { Link } from "react-router";

interface Props {
  title: string;
  titleAdornment?: React.ReactNode;
  subtitle?: string;
  backButtonLink?: string;
  onBackButtonClick?: () => void;
}

const PageHeader = ({
  title,
  titleAdornment,
  subtitle,
  backButtonLink,
  onBackButtonClick,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={2}
      px={3}
      width="100%"
      borderBottom={1}
      borderColor="divider"
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        {backButtonLink && (
          <IconButton component={Link} to={backButtonLink} color="secondary">
            <ArrowBackIcon />
          </IconButton>
        )}
        {!backButtonLink && onBackButtonClick && (
          <IconButton onClick={onBackButtonClick} color="secondary">
            <ArrowBackIcon />
          </IconButton>
        )}
        <Stack spacing={0.5}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6">{title}</Typography>
            {titleAdornment}
          </Stack>
          {subtitle && <Typography color="text.secondary">{subtitle}</Typography>}
        </Stack>
      </Stack>

      {children}
    </Box>
  );
};

export default PageHeader;
