import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router";
import Entry from "./lp/Entry";
import { store } from "./lp/store/store";
import { defined } from "./shared/utilities/typeHelper";

createRoot(defined(document.getElementById("root"))).render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Entry />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
