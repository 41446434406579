import { useState } from "react";
import { ImpersonationInvestor } from "../../api/types/impersonationTypes";
import ImpersonationInvestorSection from "./ImpersonationInvestorSection";

interface Props {
  contactEmail: string;
  investors: ImpersonationInvestor[];
  filterText: string;
  handleImpersonationView: (email: string) => void;
}

const ImpersonationInvestorList = ({ contactEmail, investors, handleImpersonationView, filterText }: Props) => {
  const [expandedInvestor, setExpandedInvestor] = useState<string | undefined>(
    () => investors.find((investor) => investor.contacts.find((contact) => contact.email === contactEmail))?.title
  );

  return (
    <>
      {investors.map((investor) => (
        <ImpersonationInvestorSection
          key={investor.title}
          investor={investor}
          filterText={filterText}
          contactEmail={contactEmail}
          expandedInvestor={expandedInvestor}
          onExpandInvestor={setExpandedInvestor}
          onContactViewClick={handleImpersonationView}
        />
      ))}
    </>
  );
};

export default ImpersonationInvestorList;
