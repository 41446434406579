import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Dialog, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Contact, FundInvestor } from "../../../../../api/types/settingsTypes";
import { workspaceInvestorSelector } from "../../../../../store/state/user/selectors";
import { systemRoleToDisplayText } from "../../../../../utilities/rolesHelper";
import { useSettingsContext } from "../../../SettingsContext";
import ContactsComponent from "../contacts/ContactsComponent";
import ViewAccess from "./ViewAccess";

interface NavigationState {
  fund?: string;
}

const MembersComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const workspaceInvestor = useSelector(workspaceInvestorSelector);
  const { fundInvestors, roles } = useSettingsContext();

  const [fundInvestorId, setFundInvestorId] = useState("");
  const [selectedContact, setSelectedContact] = useState<Contact>();
  const [viewAccess, setViewAccess] = useState(false);

  const columns: GridColDef<Contact>[] = [
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      disableColumnMenu: true,
      disableReorder: true,
      flex: 1,
      width: 180,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "access",
      headerName: "Access",
      disableColumnMenu: true,
      width: 240,
      renderCell: ({ row }) => (
        <Typography>{row.permissions.roles.map((r) => systemRoleToDisplayText(roles, r)).join(", ")}</Typography>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      disableColumnMenu: true,
      width: 40,
      renderCell: ({ row }) => {
        return (
          <Tooltip title="View access">
            <IconButton
              className="ntrl-memeber-action-btn"
              onClick={() => {
                setSelectedContact(row);
                setViewAccess(true);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const navigateToPermissions = (fundInvestor: FundInvestor | undefined) => {
    if (workspaceInvestor) {
      const url = `${workspaceInvestor.investorId}/permissions`;
      navigate(url, { state: { fund: fundInvestor?.fund.name } });
    }
  };

  const navigationState = location.state as NavigationState;

  const fundInvestor = fundInvestors.find(({ id }) => id === fundInvestorId);

  return (
    <Stack width="100%" height="100%" spacing={2} mb={3}>
      <Typography
        variant="subtitle1"
        sx={(theme) => ({
          color: theme.palette.text.primary,
          mt: "1rem",
        })}
      >
        Team
      </Typography>

      {workspaceInvestor && (
        <ContactsComponent
          investorId={workspaceInvestor.investorId}
          defaultFund={navigationState?.fund}
          columns={columns}
          actionsEl={
            <Button
              onClick={() => navigateToPermissions(fundInvestor)}
              variant="text"
              color="primary"
              sx={{ whiteSpace: "nowrap" }}
            >
              View full permissions list
            </Button>
          }
          onFundInvestorSelected={setFundInvestorId}
        />
      )}

      <Dialog
        open={viewAccess && !!selectedContact}
        onClose={(_, reason) => {
          if (reason === "backdropClick") {
            return;
          }
          setViewAccess(false);
        }}
      >
        {selectedContact && fundInvestor && (
          <ViewAccess contact={selectedContact} investor={fundInvestor} onClose={() => setViewAccess(false)} />
        )}
      </Dialog>
    </Stack>
  );
};

export default MembersComponent;
