import { RedirectKeys } from "../../shared/auth/authTypes";
import { authState, redirectToB2CAuth } from "../../shared/auth/b2c/redirects";
import { getSharedVariables } from "../../shared/variables";
import { ImpersonationDataKeys } from "./impersonationHandler";

export function redirectToB2CAuthWithImpersonation() {
  if (authState[ImpersonationDataKeys.Impersonation] === true) {
    redirectToEntriliaLogin();
  } else {
    redirectToB2CAuth();
  }
}

function redirectToEntriliaLogin() {
  const currentUrl = new URL(window.location.href);
  const search = buildSearchStringWithImpersonation();

  const redirectUrlParams = new URLSearchParams();
  redirectUrlParams.set(
    RedirectKeys.State,
    JSON.stringify({ origin: currentUrl.origin, originalPath: `${currentUrl.pathname}?${search}` })
  );

  window.location.href = `${getSharedVariables().LOGIN_APP_URI}/signin?${redirectUrlParams.toString()}`;
}

function buildSearchStringWithImpersonation(): string {
  const currentUrl = new URL(window.location.href);
  const params = new URLSearchParams(currentUrl.search);
  params.set(ImpersonationDataKeys.Impersonation, "true");

  const addParam = (key: string) => {
    if (typeof authState[key] === "string" && params.get(key) === null) {
      params.set(key, authState[key]);
    }
  };

  addParam(ImpersonationDataKeys.User);
  addParam(ImpersonationDataKeys.Client);
  addParam(ImpersonationDataKeys.Fund);

  return params.toString();
}
