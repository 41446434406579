import MonitorIcon from "@mui/icons-material/MonitorRounded";
import { Box, Grid2, Typography, useTheme } from "@mui/material";
import { ImpersonationContact, ImpersonationInvestor } from "../../api/types/impersonationTypes";

interface Props {
  contact: ImpersonationContact;
  investor: ImpersonationInvestor;
  isSelectedInvestor: boolean;
  filterText: string;
  contactEmail: string;
  onContactViewClick: (email: string) => void;
}

const ImpersonationInvestorContact = ({
  contact,
  investor,
  isSelectedInvestor,
  filterText,
  contactEmail,
  onContactViewClick,
}: Props) => {
  const theme = useTheme();
  const filterTextVal = filterText.trim().toLowerCase();

  const colorSettings = {
    color: `${contact.email === contactEmail ? "#00A866" : theme.palette.text.secondary}`,
  };

  if (
    filterTextVal !== "" &&
    !investor.title.toLowerCase().includes(filterTextVal) &&
    !contact.name.toLowerCase().includes(filterTextVal) &&
    !contact.email.toLowerCase().includes(filterTextVal) &&
    !investor.fundName.toLowerCase().includes(filterTextVal)
  ) {
    return null;
  }

  const isCurrentUser = contact.email === contactEmail && isSelectedInvestor;

  return (
    <Box
      sx={(theme) => ({
        height: "2.2rem",
        display: "flex",
        alignItems: "center",
        ...colorSettings,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
          cursor: isCurrentUser ? "arrow" : "pointer",
        },
        "& > *": {
          ...colorSettings,
        },
        paddingLeft: theme.spacing(2),
        marginLeft: theme.spacing(3),
      })}
      onClick={() => {
        if (!isCurrentUser) {
          onContactViewClick(contact.email);
        }
      }}
    >
      <Grid2
        container
        width="100%"
        sx={{
          "& > .MuiGrid-item": {
            margin: "auto",
          },
        }}
      >
        <Grid2 size={5}>
          <Typography variant="subtitle2">{contact.name}</Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography
            variant="subtitle2"
            sx={(theme) => ({
              color: colorSettings.color || theme.palette.text.secondary,
              lineHeight: "1.5rem",
            })}
          >
            {contact.email}
          </Typography>
        </Grid2>
        <Grid2 size={1}>
          {contact.email === contactEmail && isSelectedInvestor && <MonitorIcon sx={{ ...colorSettings }} />}
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default ImpersonationInvestorContact;
