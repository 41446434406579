import axios, { AxiosRequestHeaders } from "axios";
import { RedirectKeys } from "../../shared/auth/authTypes";
import { authState } from "../../shared/auth/b2c/redirects";
import { authCookieExists } from "../../shared/utilities/cookies";
import { pageRoutes } from "../routes";
import { setAuthMode } from "../variables";

export enum ImpersonationDataKeys {
  Impersonation = "impersonation",
  User = "user",
  Client = "client",
  Fund = "fund",
}

let impersonationInterceptor: number | undefined;
let isDebug = false;

export const restoreImpersonationSearchParams = (impersonationSerachParams: URLSearchParams) => {
  if (impersonationSerachParams.has(ImpersonationDataKeys.Impersonation)) {
    window.history.replaceState(
      {},
      document.title,
      `${window.location.pathname}?${impersonationSerachParams.toString()}`
    );
  }
};

export const cancelImpersonation = () => {
  if (impersonationInterceptor !== undefined) {
    axios.interceptors.request.eject(impersonationInterceptor);
  }

  setAuthMode("end_user");

  delete authState[ImpersonationDataKeys.Client];
  delete authState[ImpersonationDataKeys.Impersonation];
  delete authState[ImpersonationDataKeys.User];
  delete authState[ImpersonationDataKeys.Fund];

  isDebug = false;
};

export const viewAs = (email: string, fund: string) => {
  const getPathName = () => {
    const { pathname } = window.location;
    const segments = pathname.split("/");
    const pageName = segments[2]?.toLowerCase();

    if (pageName === pageRoutes.inbox || pageName === pageRoutes.fundraising) {
      return segments.slice(0, 3).join("/"); // Remove inbox filters or fundraising details page
    }

    return pathname;
  };

  const reloadUrlParams = new URLSearchParams();
  reloadUrlParams.set(ImpersonationDataKeys.Impersonation, "true");
  reloadUrlParams.set(ImpersonationDataKeys.User, email);
  reloadUrlParams.set(ImpersonationDataKeys.Fund, fund);

  window.location.href = `${window.location.origin}${getPathName()}?${reloadUrlParams.toString()}`;
};

export const handlePortalImpersonation = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const impersonation = !!urlParams.get(ImpersonationDataKeys.Impersonation);
  const user = urlParams.get(ImpersonationDataKeys.User);
  const fund = urlParams.get(ImpersonationDataKeys.Fund);
  const client = (window.location.pathname ? window.location.pathname.slice(1) : "").split("/")[0];
  const isImpersonationRedirect = impersonation && user && client;
  const stateParam = urlParams.get(RedirectKeys.State);
  isDebug = !!urlParams.get("debug");

  if (isImpersonationRedirect) {
    impersonationInterceptor = addImpersonationInterceptor(user, client);
    setAuthMode("impersonation");

    if (!authCookieExists()) {
      authState[ImpersonationDataKeys.Client] = client;
      authState[ImpersonationDataKeys.Impersonation] = true;
      authState[ImpersonationDataKeys.User] = user;
      if (fund) {
        authState[ImpersonationDataKeys.Fund] = fund;
      }
    }
  } else if (stateParam && stateParam.indexOf(ImpersonationDataKeys.Impersonation) > -1) {
    try {
      const state = JSON.parse(stateParam);
      if (
        state[ImpersonationDataKeys.Impersonation] &&
        state[ImpersonationDataKeys.User] &&
        state[ImpersonationDataKeys.Client]
      ) {
        impersonationInterceptor = addImpersonationInterceptor(
          state[ImpersonationDataKeys.User] as string,
          state[ImpersonationDataKeys.Client] as string
        );
        setAuthMode("impersonation");
      } else {
        throw new Error("Incorrect params for impersonation");
      }
    } catch {
      setAuthMode("end_user");
    }
  } else {
    setAuthMode("end_user");
  }
};

const addImpersonationInterceptor = (user: string, client: string): number => {
  return axios.interceptors.request.use((rc) => {
    const requestHeaders = rc.headers as AxiosRequestHeaders;
    const impersonationHeaders = createImpersonationHeaders(user, client);
    impersonationHeaders.forEach(([key, value]) => {
      requestHeaders[key] = value;
    });
    if (isDebug) {
      requestHeaders["x-entrilia-debug"] = isDebug;
    }
    return rc;
  });
};

export const setImpersonationHeaders = (
  clientCode: string | null | undefined,
  userEmail: string | null | undefined
) => {
  if (userEmail && clientCode) {
    if (impersonationInterceptor !== undefined) {
      axios.interceptors.request.eject(impersonationInterceptor);
    }
    impersonationInterceptor = addImpersonationInterceptor(userEmail, clientCode);
  }
};

export const createImpersonationHeaders = (userEmail: string, clientCode: string): Array<[string, string]> => [
  ["x-entrilia-onBehalfOfUser", encodeURIComponent(userEmail)],
  ["x-entrilia-onBehalfOfClient", clientCode],
];
