import { Card, CardContent, CardHeader, Grid2, Stack, Typography, useTheme } from "@mui/material";
import TypographyMultilineEllipsis from "../../../../../shared/components/TypographyMultilineEllipsis";
import { formatDate } from "../../../../../shared/utilities/dateUtils";
import { CardSubHeader } from "../../common/CardSubHeader";

type BalanceCellProps = {
  title: string;
  value: string;
};

const BalanceCell = ({ title, value }: BalanceCellProps) => {
  return (
    <Stack>
      <Typography color="text.secondary" variant="caption">
        {title}
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        {value}
      </Typography>
    </Stack>
  );
};

type BalanceCardProps = {
  title: string;
  investorName: string;
  date?: Date;
  fields: [string, string][]; // [title, value]
};

const BalanceCard = ({ investorName, title: fundName, date, fields }: BalanceCardProps) => {
  const theme = useTheme();
  const subtitle = date ? `as of ${formatDate(date)}` : undefined;

  return (
    <Card className="balance-card" sx={{ width: "100%", height: "100%" }}>
      <CardHeader
        title={
          <TypographyMultilineEllipsis
            maxLines={2}
            typographyProps={{ variant: "h6", sx: { color: theme.palette.primary.main } }}
          >
            {investorName}
          </TypographyMultilineEllipsis>
        }
        subheader={<CardSubHeader title={fundName} subtitle={subtitle} />}
      />
      <CardContent sx={{ pt: 0 }}>
        <Grid2 container width="100%" spacing={2}>
          {fields.map(([title, value], i) => (
            <Grid2 key={"bf" + i} size={6}>
              <BalanceCell title={title} value={value} />
            </Grid2>
          ))}
        </Grid2>
      </CardContent>
    </Card>
  );
};

export default BalanceCard;
