import documentEventsApi from "../../shared/api/clients/documentEventsApi";
import { logError } from "../../shared/logging";
import { downloadFileFromUrl, downloadFileFromUrlViaAxios } from "../../shared/services/downloadFile";
import { api } from "../api/client";
import { Message } from "../store/state/messages/types";
import { getAuthMode } from "../variables";

const sendDownloadEvent = async (documentViewSessionId: string) => {
  try {
    await documentEventsApi.ingestDocumentDownloadEvent({
      viewEventsData: [
        {
          sessionId: documentViewSessionId,
          eventData: { download: true },
        },
      ],
    });
  } catch (error) {
    logError(error, "[sendDownloadEvent]");
  }
};

export const downloadAttachment = async (messageId: string, attachmentId: string) => {
  const response = await api.attachments.getUrl(messageId, attachmentId);
  if (!response.success) {
    logError(response.error, "[downloadAttachment]");
    return;
  }

  const { downloadUrl, documentViewSessionId } = response.data;
  downloadFileFromUrl(downloadUrl);

  if (documentViewSessionId) {
    sendDownloadEvent(documentViewSessionId);
  }
};

export const downloadAll = async (messageIds: string[]) => {
  const url = await api.attachments.getUrlForAll(messageIds);
  if (!url) {
    return;
  }

  if (getAuthMode() === "impersonation") {
    downloadFileFromUrlViaAxios(url);
  } else {
    downloadFileFromUrl(url);
  }
};

export const generateAttachmentUrlData = async (messageId: string, attachmentId: string) => {
  const response = await api.attachments.getUrl(messageId, attachmentId);
  if (response?.success === true) {
    return response.data;
  }
  return Promise.reject();
};

const downloadSingleAttachmentCore = async (messageId: string) => {
  const messageDetailResponse = await api.messages.getDetails(messageId);
  if (messageDetailResponse?.data?.attachments) {
    const attachment = messageDetailResponse.data.attachments[0];
    if (attachment) {
      await downloadAttachment(messageId, attachment.id);
      return true;
    }
  }

  return false;
};

export const downloadAllAttachments = async (message: Message) => {
  if (message.numberOfAttachments === 0) {
    return;
  }

  if (message.numberOfAttachments === 1) {
    if (await downloadSingleAttachmentCore(message.id)) {
      return;
    }
  }

  await downloadAll([message.id]);
};
