import { ChangeRequestDetails } from "../../../../api/types/changeRequestsTypes";
import { InvestorFundPermissionSet } from "../../../../store/state/user/types";

export const getAvailableFundInvestorIds = (
  fundInvestors: InvestorFundPermissionSet[],
  pendingChangeRequests: ChangeRequestDetails[]
): string[] => {
  const fundInvestorIdsFromPendingRequests = pendingChangeRequests
    .map((cr) => (cr.allowedAssignedEntityChanges ?? []).map((c) => c.id))
    .flat();

  return fundInvestors
    .filter(
      (fi) =>
        fi.permissions.includes("ManageInvestorSettings") &&
        !fundInvestorIdsFromPendingRequests.includes(fi.fundInvestorId)
    )
    .map((fi) => fi.fundInvestorId);
};
