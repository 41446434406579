import React from "react";
import { Navigate } from "react-router";
import { SignupTokenData } from "../api/sso";
import FullScreenLoader from "../components/fullScreenLoader/FullScreenLoader";
import { useLocalization } from "../hooks/useLocalization";
import { RedirectKeys } from "./authTypes";
import { requestSignupToken } from "./identity";

interface IState {
  authCode: string;
  signUpState: SignUpState;
  userName: string;
  email: string;
}

enum SignUpState {
  NotSignedUp = 0,
  SignedUp = 1,
  SignUpFailed = 2,
}

type Props = Record<string, never>;

class SignUp extends React.Component<Props, IState> {
  private locale = useLocalization();

  get authCodeProvided(): boolean {
    return !!this.state.authCode;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      authCode: new URLSearchParams(window.location.search).get(RedirectKeys.Code) as string,
      signUpState: SignUpState.NotSignedUp,
    } as IState;

    if (this.authCodeProvided) {
      this.acquireSignupToken();
    }
  }

  async acquireSignupToken(): Promise<void> {
    try {
      const response = await requestSignupToken(this.state.authCode);
      if (response) {
        this.onSucceededAuth(response.data);
      }
    } catch {
      this.setState({ signUpState: SignUpState.SignUpFailed });
    }
  }

  onSucceededAuth(data: SignupTokenData) {
    this.setState({
      signUpState: SignUpState.SignedUp,
      userName: data.userName,
      email: data.email,
    });
  }

  override render() {
    if (!this.authCodeProvided) {
      return <Navigate to="/" />;
    }
    switch (this.state.signUpState) {
      case SignUpState.NotSignedUp: {
        return <FullScreenLoader title={this.locale.pages.signup.title} subtitle={this.locale.pages.signup.subtitle} />;
      }
      case SignUpState.SignedUp: {
        return <Navigate to={{ pathname: "/", search: "" }} replace />;
      }
      case SignUpState.SignUpFailed: {
        return <Navigate to={{ pathname: "/error", search: "" }} />;
      }
    }
  }
}

export default SignUp;
