import { Box } from "@mui/material";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import { createApiResponse } from "../../../shared/api/axiosHelper";
import { ApiResponse } from "../../../shared/api/types";
import DataLoadingFailed from "../../../shared/components/DataLoadingFailed";
import useFetch from "../../../shared/hooks/useFetch";
import { logError } from "../../../shared/logging";
import { api } from "../../api/client";
import { FundInvestor } from "../../api/types/settingsTypes";
import { useClientContext } from "../../contexts/ClientContext";
import { investorPermissionsSelector } from "../../store/state/user/selectors";
import { SettingsContextProvider } from "./SettingsContext";

const SettingsPage = () => {
  const { isAnyFeatureEnabled } = useClientContext();
  const investorPermissions = useSelector(investorPermissionsSelector);

  const getFundInvestors = useCallback(() => {
    const areFundInvestorsAvailable =
      isAnyFeatureEnabled(["Inbox", "AccountSettingsViewer", "BankAccountsDetailsViewer", "Membership"]) &&
      investorPermissions.length > 0;

    return areFundInvestorsAvailable
      ? api.settings.getFundInvestors()
      : Promise.resolve<ApiResponse<FundInvestor[]>>(createApiResponse([]));
  }, [investorPermissions.length, isAnyFeatureEnabled]);

  const [fundInvestors, fundInvestorsError, { isFetching: isFetchingFundInvestors }] = useFetch(getFundInvestors);

  const [accessRolesAndCategories, accessRolesAndCategoriesError, { isFetching: isFetchingAccessRolesAndCategories }] =
    useFetch(api.settings.getAccessRolesAndCategories);

  const [dictionaries, dictionariesError, { isFetching: isFetchingDictionaries }] = useFetch(
    api.dictionaries.getDictionaries
  );

  if (fundInvestorsError || accessRolesAndCategoriesError || dictionariesError) {
    logError(fundInvestorsError ?? accessRolesAndCategoriesError ?? dictionariesError, "[Settings]");
    return <DataLoadingFailed title="Failed to load settings" />;
  }

  return (
    <SettingsContextProvider
      fundInvestors={fundInvestors ?? []}
      accessCategories={accessRolesAndCategories?.categories ?? []}
      roles={accessRolesAndCategories?.roles ?? []}
      dictionaries={
        dictionaries ?? { countries: [], states: [], currencies: [], industriesAndSectors: [], geographies: [] }
      }
      loading={isFetchingFundInvestors || isFetchingAccessRolesAndCategories || isFetchingDictionaries}
    >
      <Box width="100%" display="flex" flexDirection="column" flex={1} sx={{ overflowY: "auto" }}>
        <Outlet />
      </Box>
    </SettingsContextProvider>
  );
};

export default SettingsPage;
