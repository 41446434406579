import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import FilterAutocomplete from "./FilterAutocomplete";

interface Props {
  values: string[];
  selectedValues: string[];
  onSelected: (values: string[]) => void;
  disabled?: boolean;
  placeholder?: string;
}

const PaymentStatusFilter = ({ values, selectedValues, onSelected, disabled, placeholder }: Props) => {
  return (
    <FilterAutocomplete
      disabled={disabled}
      values={values}
      selectedValues={selectedValues}
      onSelected={onSelected}
      Icon={AccountBalanceWalletOutlinedIcon}
      placeholder={placeholder || "Status"}
    />
  );
};

export default PaymentStatusFilter;
