import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, Divider } from "@mui/material";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import HorizontalFill from "../../../../shared/components/HorizontalFill";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import { useInboxStateContext } from "../../../contexts/InboxStateContext";
import { markMessageAsRead } from "../../../hooks/inboxState/inboxState.api";
import { useLocalization } from "../../../hooks/useLocalization";
import { downloadAllAttachments, downloadAttachment } from "../../../services/attachments";
import { currentMessageSelector } from "../../../store/state/messages/selectors";
import { Message } from "../../../store/state/messages/types";
import { AppDispatch } from "../../../store/store";
import Content from "./Content";
import MessageMenu from "./MessageMenu";

export const MessageComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const message = useSelector(currentMessageSelector);
  const { ui, setMessageId } = useInboxStateContext();
  const { sendNotificationError } = useNotificationContext();
  const { inbox: locale } = useLocalization();

  const onDownloadAttachment = useCallback(
    async (messageId: string, attachmentId: string) => {
      try {
        await downloadAttachment(messageId, attachmentId);
        if (!message?.isRead) {
          await markMessageAsRead(messageId)(dispatch);
        }
      } catch {
        sendNotificationError(locale.download_file_error);
      }
    },
    [dispatch, locale.download_file_error, message?.isRead, sendNotificationError]
  );

  const onDownloadAllAttachments = useCallback(
    async (message: Message) => {
      try {
        await downloadAllAttachments(message);
        if (!message.isRead) {
          await markMessageAsRead(message.id)(dispatch);
        }
      } catch {
        sendNotificationError(locale.download_file_error);
      }
    },
    [dispatch, locale.download_file_error, sendNotificationError]
  );

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" width="100%" px={2} py={1.5}>
        <Button startIcon={<ArrowBackRoundedIcon />} color={"secondary"} onClick={() => setMessageId("")}>
          Back
        </Button>
        <HorizontalFill />
        {!ui.messageDetailLoadingError && <MessageMenu downloadAllAttachments={onDownloadAllAttachments} />}
      </Box>
      <Divider flexItem />
      {ui.messageDetailLoadingError ? (
        <DataLoadingFailed />
      ) : ui.messageDetailLoading || !message ? (
        <InlineLoader />
      ) : (
        <Content downloadAttachment={onDownloadAttachment} />
      )}
    </Box>
  );
};
