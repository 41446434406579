import { useCallback } from "react";
import { Navigate, useParams } from "react-router";
import ErrorComponent from "../../../shared/components/Error";
import InlineLoader from "../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../shared/hooks/useFetch";
import { api } from "../../api/client";
import { pageRoutes } from "../../routes";

const AttachmentPreviewRedirect = () => {
  const { client, externalMessageId, externalAttachmentId } = useParams();

  const translateIds = useCallback(() => {
    if (!!externalMessageId && !!externalAttachmentId) {
      return api.messages.translateIds({ externalMessageId, externalAttachmentId });
    }
    throw new Error("externalMessageId or externalAttachmentId is empty");
  }, [externalAttachmentId, externalMessageId]);

  const [resp, error] = useFetch(translateIds);

  if (error) {
    return <ErrorComponent title="Unauthorized" subtitle="You do not have enough permissions to view this document" />;
  }

  if (!resp) {
    return <InlineLoader />;
  }

  const { messageId, attachmentId } = resp;
  return <Navigate to={`/${client}/${pageRoutes.inbox}/Inbox/message/${messageId}?attachment=${attachmentId}`} />;
};

export default AttachmentPreviewRedirect;
