import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import { Box, Button, Stack } from "@mui/material";
import React from "react";
import DateRangeSelect from "../../../shared/components/DateRangeSelect";
import RecordCounter from "../../../shared/components/filters/RecordCounter";
import SearchField from "../../../shared/components/inputs/SearchField";
import MultiSelectFilter from "../../../shared/components/MultiSelectFilter";
import {
  DescriptiveDate,
  lastYear,
  thisMonth,
  thisQuarter,
  thisYear,
} from "../../../shared/utilities/dateFilterHelper";
import { DataCollectionSubmissionFilterOptions } from "../../api/types/dataCollectionTypes";
import { DataSubmissionsFilterState } from "./dataSubmissionsFilterState";

interface Props {
  recordCount: number;
  isLoading: boolean;
  filterOptions: DataCollectionSubmissionFilterOptions;
  filterState: DataSubmissionsFilterState;
  setFilterState: React.Dispatch<React.SetStateAction<DataSubmissionsFilterState>>;
}

const dateRangeOptions = [thisMonth, thisQuarter, thisYear, lastYear];

const DataSubmissionsFilterPanel = ({ recordCount, isLoading, filterOptions, filterState, setFilterState }: Props) => {
  const { requestTemplateIds } = filterOptions;

  const handleSearch = (searchValue: string) => {
    setFilterState((prev) => ({ ...prev, searchValue }));
  };

  const handleTemplateIdsChange = (selectedTemplateIds: string[]) => {
    setFilterState((prev) => ({ ...prev, selectedTemplateIds }));
  };

  const handleReportingDateRangeChange = (reportingDateRange: DescriptiveDate) => {
    setFilterState((prev) => ({ ...prev, reportingDateRange }));
  };

  const handleClearFilters = () => {
    setFilterState((prev) => ({ ...prev, selectedTemplateIds: [], reportingDateRange: undefined }));
  };

  const typeOptions = requestTemplateIds.map(({ id, name }) => ({ value: id, label: name }));

  const showClearFilters = !isLoading && (filterState.selectedTemplateIds.length > 0 || filterState.reportingDateRange);

  return (
    <Box display="flex" justifyContent="space-between" pb={2.5}>
      <Stack direction="row" spacing={1} alignItems="center">
        <MultiSelectFilter
          options={typeOptions}
          selectedValues={filterState.selectedTemplateIds}
          sortOptions
          onChange={handleTemplateIdsChange}
          disabled={isLoading || requestTemplateIds.length === 0}
          placeholder="Type"
          StartIcon={DescriptionIcon}
        />
        <DateRangeSelect
          predefinedRanges={dateRangeOptions}
          dateRange={filterState.reportingDateRange}
          onChange={handleReportingDateRangeChange}
          placeholder="Reporting Date"
          disabled={isLoading}
        />
        {showClearFilters && (
          <Button variant="text" onClick={handleClearFilters}>
            Clear all
          </Button>
        )}
        <Box pl={1}>
          <RecordCounter records={recordCount} hide={isLoading} />
        </Box>
      </Stack>
      <SearchField
        debounceTimeMs={300}
        initialValue={filterState.searchValue}
        onSearch={handleSearch}
        disabled={isLoading}
      />
    </Box>
  );
};

export default DataSubmissionsFilterPanel;
