import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import UserAvatar from "../../../../shared/components/UserAvatar";
import {
  identityAvatarImageSelector,
  identityNameSelector,
  userEmailSelector,
  userNameSelector,
} from "../../../store/state/user/selectors";

const UserComponent = () => {
  const userName = useSelector(userNameSelector);
  const userEmail = useSelector(userEmailSelector);
  const identityName = useSelector(identityNameSelector);
  const avatarImage = useSelector(identityAvatarImageSelector);

  return (
    <Stack direction="row" width="100%" spacing={1.5} alignItems="center">
      <UserAvatar size="large" userName={userName} avatarImage={avatarImage} />
      <Stack spacing={0.5}>
        <Typography whiteSpace="break-spaces" variant="h6" color="primary">
          {identityName || userName}
        </Typography>
        {userEmail && (
          <Typography variant="caption" color="text.secondary" noWrap>
            {userEmail}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default UserComponent;
