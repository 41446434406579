import { distinct } from "../../../../../../shared/utilities/arrayHelper";
import { countryCodeAlpha2Lookup } from "../../../../../../shared/utilities/countriesProvider";
import {
  bicCodeValidator,
  combineValidators,
  maxCharactersValidator,
  requiredValidator,
  routingNumberValidator,
  swiftCompliantCharsValidator,
} from "../../../../../../shared/utilities/validators";
import { CreateInvestorBankAccountRequest } from "../../../../../api/types/bankAccountTypes";
import { InvestorFundPermissionSet } from "../../../../../store/state/user/types";

export interface NewBankAccountState {
  step: StateStep;
  isNextStepDisabled: boolean;
  bankAccountForm: BankAccountForm;
  fundAssignments: NewBankAccountFundAssignment[];
}

export enum StateStep {
  BankAccount = 0,
  FundAssignments = 1,
  Review = 2,
}

interface BankAccountForm {
  addForFurtherCredit: boolean;
  countryCode: string | undefined;
  values: BankAccountFormValues;
  touchedFields: (keyof BankAccountFormValues)[];
  errors: BankAccountFormErrors;
}

interface BankAccountFormErrors {
  country?: string;
  bankName?: string;
  abaNumber?: string;
  swiftCode?: string;
  accountName?: string;
  accountNumber?: string;
  currencyCode?: string;
  ffcName?: string;
  ffcAccountNumber?: string;
}

interface BankAccountFormValues {
  country: string;
  bankName: string;
  abaNumber: string;
  swiftCode: string;
  accountName: string;
  accountNumber: string;
  currencyCode: string;
  ffcName: string;
  ffcAccountNumber: string;
}

export interface NewBankAccountFundAssignment {
  fundInvestorId: string;
  fundName: string;
  selected: boolean;
  disabled: boolean;
}

export type Action =
  | {
      type: "next_step";
    }
  | {
      type: "previous_step";
    }
  | {
      type: "update_field";
      field: keyof BankAccountFormValues;
      value: string;
    }
  | {
      type: "update_addForFurtherCredit";
      value: boolean;
    }
  | {
      type: "toggle_fund_assignment";
      fundInvestorId: string;
    };

export const getInitialState = (
  fundInvestors: InvestorFundPermissionSet[],
  availableFundInvestorIds: string[]
): NewBankAccountState => ({
  step: StateStep.BankAccount,
  isNextStepDisabled: true,
  bankAccountForm: {
    addForFurtherCredit: false,
    countryCode: undefined,
    values: {
      country: "",
      bankName: "",
      abaNumber: "",
      swiftCode: "",
      accountName: "",
      accountNumber: "",
      currencyCode: "",
      ffcName: "",
      ffcAccountNumber: "",
    },
    touchedFields: [],
    errors: {},
  },
  fundAssignments: fundInvestors.map((fundInvestor) => ({
    fundInvestorId: fundInvestor.fundInvestorId,
    fundName: fundInvestor.fundName,
    selected: false,
    disabled: !availableFundInvestorIds.includes(fundInvestor.fundInvestorId),
  })),
});

const validateForm = (
  formFields: BankAccountFormValues,
  addForFurtherCredit: boolean,
  countryCode: string | undefined
): BankAccountFormErrors => {
  if (countryCode === "US") {
    return {
      country: requiredValidator(formFields.country).error,
      bankName: combineValidators(requiredValidator, maxCharactersValidator(100))(formFields.bankName).error,
      abaNumber: combineValidators(requiredValidator, routingNumberValidator)(formFields.abaNumber).error,
      swiftCode: bicCodeValidator(formFields.swiftCode).error,
      accountName: combineValidators(requiredValidator, maxCharactersValidator(100))(formFields.accountName).error,
      accountNumber: combineValidators(requiredValidator, maxCharactersValidator(30))(formFields.accountNumber).error,
      currencyCode: requiredValidator(formFields.currencyCode).error,
      ffcName: addForFurtherCredit
        ? combineValidators(requiredValidator, maxCharactersValidator(100))(formFields.ffcName).error
        : undefined,
      ffcAccountNumber: addForFurtherCredit
        ? combineValidators(requiredValidator, maxCharactersValidator(30))(formFields.ffcAccountNumber).error
        : undefined,
    };
  }

  return {
    country: requiredValidator(formFields.country).error,
    bankName: combineValidators(requiredValidator, maxCharactersValidator(100))(formFields.bankName).error,
    abaNumber: undefined,
    swiftCode: combineValidators(requiredValidator, bicCodeValidator)(formFields.swiftCode).error,
    accountName: combineValidators(
      requiredValidator,
      swiftCompliantCharsValidator,
      maxCharactersValidator(100)
    )(formFields.accountName).error,
    accountNumber: combineValidators(
      requiredValidator,
      swiftCompliantCharsValidator,
      maxCharactersValidator(30)
    )(formFields.accountNumber).error,
    currencyCode: requiredValidator(formFields.currencyCode).error,
    ffcName: addForFurtherCredit
      ? combineValidators(
          requiredValidator,
          swiftCompliantCharsValidator,
          maxCharactersValidator(100)
        )(formFields.ffcName).error
      : undefined,
    ffcAccountNumber: addForFurtherCredit
      ? combineValidators(
          requiredValidator,
          swiftCompliantCharsValidator,
          maxCharactersValidator(30)
        )(formFields.ffcAccountNumber).error
      : undefined,
  };
};

export const stateToCreateInvestorBankAccountRequest = (
  state: NewBankAccountState
): CreateInvestorBankAccountRequest => ({
  fundInvestorIds: state.fundAssignments
    .filter((assignment) => assignment.selected)
    .map((assignment) => assignment.fundInvestorId),
  bankName: state.bankAccountForm.values.bankName.trim(),
  country: state.bankAccountForm.values.country,
  currencyCode: state.bankAccountForm.values.currencyCode,
  accountName: state.bankAccountForm.values.accountName.trim(),
  accountNumber: state.bankAccountForm.values.accountNumber.trim(),
  swiftCode: state.bankAccountForm.values.swiftCode.trim() || undefined,
  abaNumber:
    state.bankAccountForm.countryCode === "US" ? state.bankAccountForm.values.abaNumber.trim() || undefined : undefined,
  ffcName: state.bankAccountForm.addForFurtherCredit
    ? state.bankAccountForm.values.ffcName.trim() || undefined
    : undefined,
  ffcAccountNumber: state.bankAccountForm.addForFurtherCredit
    ? state.bankAccountForm.values.ffcAccountNumber.trim() || undefined
    : undefined,
});

export const reducer = (state: NewBankAccountState, action: Action): NewBankAccountState => {
  switch (action.type) {
    case "next_step": {
      const nextStep = state.step + 1;
      return {
        ...state,
        isNextStepDisabled: nextStep !== StateStep.Review,
        step: nextStep,
      };
    }
    case "previous_step":
      return {
        ...state,
        isNextStepDisabled: false,
        step: state.step - 1,
      };
    case "update_field": {
      const countryCode =
        action.field === "country" ? countryCodeAlpha2Lookup.get(action.value) : state.bankAccountForm.countryCode;
      const updatedFields: BankAccountFormValues = { ...state.bankAccountForm.values, [action.field]: action.value };
      const allErrors = validateForm(updatedFields, state.bankAccountForm.addForFurtherCredit, countryCode);
      const isFormValid = Object.values(allErrors).every((error) => !error);
      const touchedFields = distinct([...state.bankAccountForm.touchedFields, action.field]);
      const visibleErrors = Object.fromEntries(
        Object.entries(allErrors).filter(([key]) => touchedFields.includes(key as keyof BankAccountFormValues))
      );

      return {
        ...state,
        isNextStepDisabled: !isFormValid,
        bankAccountForm: {
          ...state.bankAccountForm,
          countryCode: countryCode,
          values: updatedFields,
          touchedFields,
          errors: visibleErrors,
        },
      };
    }
    case "update_addForFurtherCredit": {
      const allErrors = validateForm(state.bankAccountForm.values, action.value, state.bankAccountForm.countryCode);
      const isFormValid = Object.values(allErrors).every((error) => !error);
      const visibleErrors = Object.fromEntries(
        Object.entries(allErrors).filter(([key]) =>
          state.bankAccountForm.touchedFields.includes(key as keyof BankAccountFormValues)
        )
      );

      return {
        ...state,
        isNextStepDisabled: !isFormValid,
        bankAccountForm: {
          ...state.bankAccountForm,
          addForFurtherCredit: action.value,
          errors: visibleErrors,
        },
      };
    }
    case "toggle_fund_assignment": {
      const newFundAssignments = state.fundAssignments.map((assignment) =>
        assignment.fundInvestorId === action.fundInvestorId
          ? { ...assignment, selected: !assignment.selected }
          : assignment
      );

      return {
        ...state,
        isNextStepDisabled: newFundAssignments.every((assignment) => !assignment.selected),
        fundAssignments: newFundAssignments,
      };
    }
    default:
      return state;
  }
};
