import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";

import useSignalR from "../../../shared/hooks/useSignalR";
import { getBackendBaseUrl } from "../../../shared/variables";
import { InboxStateContextProvider } from "../../contexts/InboxStateContext";
import { useInboxState } from "../../hooks/inboxState/inboxState";

import { inboxStructureLoadingSelector } from "../../store/state/inboxStructure/selectors";
import { InboxStructureLoadingEnum } from "../../store/state/inboxStructure/types";
import { filterCategoriesSelector, filterLoadingSelector } from "../../store/state/messages/selectors";
import { loadConfiguration } from "../../store/state/messages/thunks";
import { FilterConfigurationLoadingState } from "../../store/state/messages/types";
import {
  clientCodeSelector,
  impersonationSelector,
  loadingIdentitySelector,
  userEmailSelector,
} from "../../store/state/user/selectors";
import { AppDispatch } from "../../store/store";

import { api } from "../../api/client";
import { createImpersonationHeaders } from "../../impersonation/impersonationHandler";

import DataLoadingFailed from "../../../shared/components/DataLoadingFailed";
import { useQueryParams } from "../../hooks/useQueryParams";
import FeatureBoundary from "../common/FeatureBoundary";
import Banner from "../dashboard/Banner";

interface Props {
  keepPage?: boolean;
}

const InboxContainer = ({ keepPage }: Props) => {
  const { tag, id, page } = useParams();

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useQueryParams();

  const refresh = (location.state as { refresh: boolean })?.refresh === true;

  const attachmentId = queryParams.get("attachment") ?? undefined;

  const categories = useSelector(filterCategoriesSelector);
  const identityLoading = useSelector(loadingIdentitySelector);
  const filterConfigurationLoading = useSelector(filterLoadingSelector);
  const inboxStructureLoading = useSelector(inboxStructureLoadingSelector);
  const isImpersonationMode = useSelector(impersonationSelector);
  const userEmail = useSelector(userEmailSelector);
  const clientCode = useSelector(clientCodeSelector);

  const pageIndex = keepPage ? Number(page?.slice(1) || "0") : 0;

  const {
    path,
    criteria,
    ui,
    messageId,
    updateCriteria,
    updateCriteriaPreload,
    updateUi,
    setMessageId,
    searchWithCurrentCriteria,
  } = useInboxState(categories || [], tag, id, refresh, pageIndex, attachmentId);

  useEffect(() => {
    dispatch(loadConfiguration());
  }, [dispatch]);

  useEffect(() => {
    if (path) {
      navigate(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useSignalR({
    url: `${getBackendBaseUrl("ir-portal")}/inboxHub`,
    subscriptions: [
      {
        eventName: "inboxUpdated",
        callback: () => searchWithCurrentCriteria(),
      },
    ],
    customHeaders: Object.fromEntries(createImpersonationHeaders(userEmail, clientCode)),
    onConnected: async (connection) => {
      const { data: subscriptionToken } = await api.inboxUpdatesSubscription.getToken();
      if (subscriptionToken) {
        await connection.invoke("Subscribe", subscriptionToken);
      }
    },
    filter: () => isImpersonationMode,
  });

  if (identityLoading) {
    return <></>;
  }

  if (
    filterConfigurationLoading === FilterConfigurationLoadingState.Error ||
    inboxStructureLoading === InboxStructureLoadingEnum.Error
  ) {
    return <DataLoadingFailed />;
  }

  return (
    <InboxStateContextProvider
      value={{
        criteria,
        ui,
        messageId,
        updateCriteria,
        updateCriteriaPreload,
        updateUi,
        setMessageId,
      }}
    >
      <FeatureBoundary requiredAbsence="Dashboards">
        <Banner />
      </FeatureBoundary>
      <Box display="flex" width="100%" flex={1}>
        <Outlet />
      </Box>
    </InboxStateContextProvider>
  );
};

export default InboxContainer;
