import { SvgIconComponent } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Link as RouterLink } from "react-router";
import { useClientContext } from "../../contexts/ClientContext";

interface Props {
  text: string;
  page: string;
  disabled?: boolean;
  Icon: SvgIconComponent;
}

export const UserMenuItem = ({ text, page, Icon, disabled }: Props) => {
  const { clientCode } = useClientContext();

  return (
    <MenuItem disabled={!!disabled} component={RouterLink} to={`/${clientCode}/${page}`} sx={{ pl: 3, py: 1 }}>
      <ListItemIcon>
        <Icon color="secondary" />
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </MenuItem>
  );
};

export default UserMenuItem;
