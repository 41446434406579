import AddIcon from "@mui/icons-material/Add";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Stack, Tab, Typography } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import BankAccountCardsView from "./BankAccountCardsView";
import { useBankAccountsContext } from "./BankAccountsContext";
import ChangeRequestsStatus from "./ChangeRequestsStatus";
import FundAssignmentsView from "./FundAssignmentsView";

interface Props {
  onAddBankAccount: () => void;
  onChangeRequestCanceled: (workflowId: string) => void;
}

type TabValue = "fund_assignments" | "bank_accounts";

const Panel = ({ children, value }: PropsWithChildren<{ value: TabValue }>) => (
  <TabPanel value={value} sx={{ px: 0, py: 0.5 }}>
    {children}
  </TabPanel>
);

const BankAccountsView = ({ onAddBankAccount, onChangeRequestCanceled }: Props) => {
  const { canUserEdit, availableFundInvestorIds } = useBankAccountsContext();

  const [selectedTab, setSelectedTab] = useState<TabValue>("fund_assignments");

  return (
    <Stack spacing={2} width="100%">
      {canUserEdit && (
        <Stack spacing={2}>
          <ChangeRequestsStatus onChangeRequestCanceled={onChangeRequestCanceled} />
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography color="text.secondary">
              These bank accounts will be used to receive distributions from your fund investments. You have the option
              to assign different bank accounts for each fund.
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={onAddBankAccount}
              sx={{ width: "15.5rem" }}
              disabled={availableFundInvestorIds.length === 0}
            >
              Add Bank Account
            </Button>
          </Stack>
        </Stack>
      )}
      {!canUserEdit && (
        <Typography color="text.secondary">
          These bank accounts will be used to receive distributions from your fund investments.
        </Typography>
      )}

      <TabContext value={selectedTab}>
        <TabList onChange={(_, value) => setSelectedTab(value as TabValue)}>
          <Tab value="fund_assignments" label="Fund Assignments" />
          <Tab value="bank_accounts" label="Bank Accounts" />
        </TabList>
        <Panel value="fund_assignments">
          <FundAssignmentsView />
        </Panel>
        <Panel value="bank_accounts">
          <BankAccountCardsView />
        </Panel>
      </TabContext>
    </Stack>
  );
};

export default BankAccountsView;
