import { Box, Grid2, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { convertISODateShort } from "../../../../../../shared/utilities/dateUtils";
import { getEntityFromMessage, getFundFromMessage } from "../../../../../store/state/messages/messageHelper";
import { Message } from "../../../../../store/state/messages/types";
import { impersonationSelector } from "../../../../../store/state/user/selectors";
import { MessageLevelComponent } from "../../../messageLevel/MessageLevelComponent";
import MessagePublishStatusIcon from "../../MessagePublishStatusIcon";
import MessageTypeComponent from "../../MessageType";

interface ItemTextProps {
  text: string | undefined;
  emphasize: boolean;
  subText?: string;
}

const ItemText = ({ text, emphasize, subText }: ItemTextProps) => {
  const fontWeight = emphasize ? "bold" : "normal";

  return (
    <Stack sx={{ whiteSpace: "normal" }}>
      <Typography variant="subtitle2" fontWeight={fontWeight}>
        {text}
      </Typography>
      {subText && (
        <Typography variant="caption" color="text.secondary" fontWeight={fontWeight}>
          {subText}
        </Typography>
      )}
    </Stack>
  );
};

interface Props {
  message: Message;
}

export const MessageItemComponent = (props: Props) => {
  const { message } = props;
  const isImpersonation = useSelector(impersonationSelector);

  return (
    <Stack width="100%" flex={1} py={1} pr={1.5} alignItems="flex-start" spacing={0.5}>
      <Box width="100%" display="flex" flex={1} justifyContent="space-between" alignItems="center">
        <ItemText
          emphasize={!message.isRead}
          text={getFundFromMessage(message)}
          subText={getEntityFromMessage(message)}
        />
        <ItemText emphasize={!message.isRead} text={convertISODateShort(message.sendDate)} />
      </Box>

      <Grid2 container width="100%" alignItems="center" spacing={1}>
        <Grid2 size={9}>
          <ItemText emphasize={!message.isRead} text={message.content.subject} />
        </Grid2>
        <Grid2 size={2} alignItems="flex-end">
          <MessageTypeComponent message={message} />
        </Grid2>
      </Grid2>

      <Stack width="100%" direction="row" spacing={1} alignItems="center">
        <MessageLevelComponent level={message.messageLevel} value={message.categoryId} />
        {isImpersonation && (
          <>
            <MessagePublishStatusIcon publishingStatus={message.publishingStatus} />
            <ItemText emphasize={!message.isRead} text={message.publishingStatus} />
          </>
        )}
      </Stack>
    </Stack>
  );
};
