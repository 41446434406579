import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import NoAccessIcon from "../icons/NoAccessIcon";
import InfoPage from "./infoPage/InfoPage";

interface Props {
  userEmail?: string;
}

const NoAccessPage = ({ userEmail, children }: PropsWithChildren<Props>) => {
  return (
    <InfoPage
      Icon={<NoAccessIcon />}
      title="You don't have access"
      subtitle={
        <Typography color="text.secondary" maxWidth="40rem">
          It seems you do not have access to this portal or it may not exist. If you believe this to be an error, please
          contact your fund manager for further assistance.
          {userEmail ? (
            <>
              {" "}
              You&apos;re signed in as <u>{userEmail}</u>.
            </>
          ) : null}
        </Typography>
      }
    >
      {children}
    </InfoPage>
  );
};

export default NoAccessPage;
