import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { InvestorBankAccountAssignment, InvestorBankAccountDetails } from "../../../../api/types/bankAccountTypes";
import { ChangeRequestDetails } from "../../../../api/types/changeRequestsTypes";
import { InvestorFundPermissionSet } from "../../../../store/state/user/types";

interface ContextValue {
  canUserEdit: boolean;
  investorId: string;
  fundInvestors: InvestorFundPermissionSet[];
  availableFundInvestorIds: string[];
  bankAccounts: InvestorBankAccountDetails[];
  fundAssignments: InvestorBankAccountAssignment[];
  pendingChangeRequests: ChangeRequestDetails[];
}

const BankAccountsContext = createContext<ContextValue | undefined>(undefined);

export const BankAccountsContextProvider = ({
  children,
  canUserEdit,
  investorId,
  fundInvestors,
  availableFundInvestorIds,
  bankAccounts,
  fundAssignments,
  pendingChangeRequests,
}: PropsWithChildren<ContextValue>) => {
  return (
    <BankAccountsContext.Provider
      value={{
        canUserEdit,
        investorId,
        fundInvestors,
        availableFundInvestorIds,
        bankAccounts,
        fundAssignments,
        pendingChangeRequests,
      }}
    >
      {children}
    </BankAccountsContext.Provider>
  );
};

export const useBankAccountsContext = () =>
  defined(useContext(BankAccountsContext), "Attempt to use BankAccountsContext without provider");
