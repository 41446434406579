import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { green, lime, red } from "@mui/material/colors";
import { PropsWithChildren, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ColorTag from "../../../../shared/components/ColorTag";
import TypographyTooltipEllipsis from "../../../../shared/components/TypographyTooltipEllipsis";
import { convertISODate } from "../../../../shared/utilities/dateUtils";
import { formatMoney } from "../../../../shared/utilities/formatters";
import { defined } from "../../../../shared/utilities/typeHelper";
import { useClientContext } from "../../../contexts/ClientContext";
import { useLocalization } from "../../../hooks/useLocalization";
import { pageRoutes } from "../../../routes";
import { selectCashFlowRequestData } from "../../../store/state/dashboard/selectors";
import {
  CapCallOrDistribution,
  CapCallOrDistributionTransaction,
  CapCallOrDistributionTransactionImpact,
  DistributionStatus,
} from "../../../store/state/dashboard/types";

interface Props {
  cashFlowCapCalls: CapCallOrDistribution[];
  showInvestorAndFund?: boolean;
  showAll?: boolean;
}

interface MasterRowProps {
  row: CapCallOrDistribution;
  showInvestorAndFund: boolean;
  expanded: boolean;
  onToggleClick: (rowId: string) => void;
}

interface DetailRowProps {
  transaction: CapCallOrDistributionTransaction;
  showInvestorAndFund: boolean;
}

interface ExpandedWrapperProps {
  expanded: boolean;
  showInvestorAndFund: boolean;
}

const getRowWidths = (showInvestorAndFund: boolean) =>
  showInvestorAndFund
    ? {
        name: "20%",
        status: 130,
        investor: 240,
        fund: "auto",
        date: 120,
        amount: 200,
        unfunded: 200,
      }
    : {
        name: "auto",
        status: 130,
        date: 120,
        amount: 200,
        unfunded: 200,
      };

const MasterHeadRow = ({ showInvestorAndFund }: { showInvestorAndFund: boolean }) => {
  const locale = useLocalization().dashboard.cashFlows.header;
  const rowWidths = getRowWidths(showInvestorAndFund);

  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell>{locale.name}</TableCell>
      <TableCell>{locale.status}</TableCell>
      {showInvestorAndFund && <TableCell>{locale.investor}</TableCell>}
      {showInvestorAndFund && <TableCell>{locale.fund}</TableCell>}
      <TableCell width={rowWidths.date}>{locale.due_date}</TableCell>
      <TableCell width={rowWidths.amount} align="right">
        {locale.amount}
      </TableCell>
      <TableCell width={rowWidths.unfunded} align="right">
        {locale.unfunded}
      </TableCell>
    </TableRow>
  );
};

const MasterRow = ({ row, showInvestorAndFund, expanded, onToggleClick }: MasterRowProps) => {
  const handleToggleButtonClick = () => onToggleClick(row.id);

  const cashFlowsLocale = useLocalization().dashboard.cashFlows;

  const statusMap: Record<DistributionStatus, { statusText: string; statusColor: string }> = {
    Unpaid: { statusText: cashFlowsLocale.payment_status.unpaid, statusColor: red[50] },
    PartlyPaid: { statusText: cashFlowsLocale.payment_status.partly_paid, statusColor: lime[50] },
    Paid: { statusText: cashFlowsLocale.payment_status.paid, statusColor: green[50] },
  };

  const { statusText, statusColor } = statusMap[row.status];
  const rowWidths = getRowWidths(showInvestorAndFund);
  const rowCurrency = row.transactions.find((t) => !!t.currency)?.currency || "USD";

  return (
    <TableRow hover={true} sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell padding="checkbox">
        <IconButton onClick={handleToggleButtonClick}>
          {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </TableCell>
      <TableCell width={rowWidths.name}>{row.name}</TableCell>
      <TableCell width={rowWidths.status}>
        <ColorTag text={statusText} color={statusColor} />
      </TableCell>
      {showInvestorAndFund && (
        <TableCell width={rowWidths.investor}>
          <TypographyTooltipEllipsis
            text={row.investorTitle}
            typographyProps={{ width: defined(rowWidths.investor) - 32 }}
          />
        </TableCell>
      )}
      {showInvestorAndFund && <TableCell width={rowWidths.fund}>{row.fundName}</TableCell>}
      <TableCell width={rowWidths.date}>{convertISODate(row.date)}</TableCell>
      <TableCell width={rowWidths.amount} align="right">
        <Typography variant="subtitle2">
          {formatMoney(row.amount * -1, rowCurrency, { currencySign: "accounting" })}
        </Typography>
      </TableCell>
      <TableCell width={rowWidths.unfunded} align="right">
        <Typography variant="subtitle2">
          {formatMoney(row.unfunded, rowCurrency, { currencySign: "accounting" })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const DetailRow = ({ transaction, showInvestorAndFund }: DetailRowProps) => {
  const rowWidths = getRowWidths(showInvestorAndFund);
  const locale = useLocalization();

  const impactTextMap: Record<CapCallOrDistributionTransactionImpact, string> = {
    None: locale.dashboard.cashFlows.impacts.no_impact,
    Increases: locale.dashboard.cashFlows.impacts.increases,
    Decreases: locale.dashboard.cashFlows.impacts.decreases,
  };

  return (
    <TableRow
      hover={true}
      sx={(them) => ({
        "&.MuiTableRow-root": {
          borderBottom: `1px dashed ${them.palette.divider}`,
          "&:last-child": {
            borderBottom: 0,
          },
          td: {
            borderBottom: 0,
          },
        },
      })}
    >
      <TableCell width="auto" align="left" sx={{ pl: 2 }}>
        <Typography color="text.secondary">{transaction.detailsDescription}</Typography>
      </TableCell>
      <TableCell width={rowWidths.amount} sx={{ pr: 1 }} align="right">
        <Typography color="text.secondary">
          {formatMoney(transaction.netAmount * -1, transaction.currency, { currencySign: "accounting" })}
        </Typography>
      </TableCell>
      <TableCell width={rowWidths.unfunded} sx={{ pr: 1 }} align="right">
        <Typography color="text.secondary">{impactTextMap[transaction.impact]}</Typography>
      </TableCell>
    </TableRow>
  );
};

const ExpandedWrapper = ({ expanded, children, showInvestorAndFund }: PropsWithChildren<ExpandedWrapperProps>) => {
  const cellSx = { p: 0, border: expanded ? "" : "unset" };
  return (
    <TableRow>
      <TableCell sx={cellSx} colSpan={3} />
      <TableCell sx={cellSx} colSpan={showInvestorAndFund ? 8 : 6}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={(theme) => ({ bgColor: theme.palette.grey[100], mr: 1, my: 1.5 })}>
            <Table>
              <TableBody>{children}</TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

const RowWithDetails = ({ row, showInvestorAndFund, expanded, onToggleClick }: MasterRowProps) => (
  <>
    <MasterRow row={row} showInvestorAndFund={showInvestorAndFund} expanded={expanded} onToggleClick={onToggleClick} />
    <ExpandedWrapper expanded={expanded} showInvestorAndFund={showInvestorAndFund}>
      {row.transactions.map((transaction) => (
        <DetailRow key={transaction.id} transaction={transaction} showInvestorAndFund={showInvestorAndFund} />
      ))}
    </ExpandedWrapper>
  </>
);

const ViewAllTableFooterRow = () => {
  const navigate = useNavigate();
  const cashFlowsLocale = useLocalization().dashboard.cashFlows;
  const { clientCode } = useClientContext();

  const navigateToCashFlows = () => {
    navigate(`/${clientCode}/${pageRoutes.dashboard}/cash-flows`);
  };

  return (
    <TableRow key="cffr">
      <TableCell colSpan={2} sx={{ borderBottomWidth: 0 }}>
        <Button variant="text" endIcon={<ArrowForwardRoundedIcon />} onClick={() => navigateToCashFlows()}>
          {cashFlowsLocale.view_all}
        </Button>
      </TableCell>
    </TableRow>
  );
};

const EmptyDataRow = () => {
  const cashFlowsLocale = useLocalization().dashboard.cashFlows;
  return (
    <TableRow key="empty">
      <TableCell colSpan={8} align="center">
        <Typography color="text.secondary">{cashFlowsLocale.no_data}</Typography>
      </TableCell>
    </TableRow>
  );
};

const CashFlowsTable = ({ cashFlowCapCalls: capCalls, showInvestorAndFund, showAll }: Props) => {
  const [expandedRowIds, setExpandedRowIds] = useState(Object.fromEntries(capCalls.map((row) => [row.id, false])));
  const handleRowToggle = (id: string) => setExpandedRowIds((rowIds) => ({ ...rowIds, [id]: !rowIds[id] }));

  const cashFlowRequest = useSelector(selectCashFlowRequestData);
  const showViewAll = !showAll && cashFlowRequest && capCalls.length >= cashFlowRequest?.paging.size;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <MasterHeadRow showInvestorAndFund={!!showInvestorAndFund} />
        </TableHead>
        <TableBody>
          {capCalls.length > 0 ? (
            capCalls.map((row) => (
              <RowWithDetails
                key={row.id}
                row={row}
                showInvestorAndFund={!!showInvestorAndFund}
                expanded={!!expandedRowIds[row.id]}
                onToggleClick={handleRowToggle}
              />
            ))
          ) : (
            <EmptyDataRow />
          )}
        </TableBody>
        {showViewAll && (
          <TableFooter>
            <ViewAllTableFooterRow />
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default CashFlowsTable;
