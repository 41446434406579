import { GridEventListener } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router";
import DataGrid from "../../../shared/components/grid/DataGrid";
import DocumentIcon from "../../../shared/icons/DocumentIcon";
import { DataCollectionSubmissionInfo } from "../../api/types/dataCollectionTypes";
import { columnDefinitions } from "./dataSubmissionsGridDataProvider";

interface Props {
  rows: DataCollectionSubmissionInfo[];
  noRowsText: string;
  isLoading: boolean;
  hiddenColumns?: string[];
}

const DataSubmissionsGrid = ({ rows, noRowsText, isLoading, hiddenColumns }: Props) => {
  const navigate = useNavigate();

  const handleCellClick: GridEventListener<"cellClick"> = ({ row }) => {
    navigate(`submissions/${row.id}`);
  };

  return (
    <DataGrid<DataCollectionSubmissionInfo>
      rows={rows}
      columns={columnDefinitions}
      columnVisibilityModel={(hiddenColumns ?? []).reduce((result, field) => ({ ...result, [field]: false }), {})}
      loading={isLoading}
      noRowsIcon={<DocumentIcon sx={{ width: 80, height: 80 }} />}
      noRowsText={noRowsText}
      selectableRows
      onCellClick={handleCellClick}
      disableColumnReorder
      disableColumnSorting
    />
  );
};

export default DataSubmissionsGrid;
