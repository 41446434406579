import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Avatar, Button, CardHeader, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import HorizontalSwiper from "../../../../../shared/components/HorizontalSwiper";
import { logError } from "../../../../../shared/logging";
import { useLocalization } from "../../../../hooks/useLocalization";
import {
  selectCashFlowData,
  selectCashFlowError,
  selectCashFlowFiltersApplied,
  selectCashFlowLoading,
} from "../../../../store/state/dashboard/selectors";
import { fetchCashFlowData } from "../../../../store/state/dashboard/thunk";
import { cashFlowDefaults } from "../../../../store/state/dashboard/types";
import { clientCodeSelector } from "../../../../store/state/user/selectors";
import { AppDispatch } from "../../../../store/store";
import { cardHeaderPropsDefault } from "../../common/helpers";
import NoDataResult from "../../common/NoDataResult";
import CashFlowCard from "./CashFlowCard";
import CashFlowsModalPage from "./CashFlowsModalPage";

const CashFlowWidgetSkeleton = () => {
  return (
    <Stack>
      <CardHeader
        sx={{ ...cardHeaderPropsDefault, pb: 2 }}
        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
        title={<Skeleton animation="wave" height={20} width="40%" />}
      />
      <Skeleton sx={{ height: 190, mx: 2, borderRadius: 1 }} animation="wave" variant="rectangular" />
    </Stack>
  );
};

const CashFlowsWidgetMobile = () => {
  const dispatch: AppDispatch = useDispatch();
  const locale = useLocalization().dashboard.cashFlows;
  const cashFlowLoading = useSelector(selectCashFlowLoading);
  const cashFlowError = useSelector(selectCashFlowError);
  const cashFlowCapCalls = useSelector(selectCashFlowData);
  const client = useSelector(clientCodeSelector);
  const filtersApplied = useSelector(selectCashFlowFiltersApplied);
  const [showModalPage, setShowModalPage] = useState(false);

  if (cashFlowError) {
    logError(cashFlowError, "CashFlowWidget");
  }

  useEffect(() => {
    dispatch(fetchCashFlowData({ paging: { page: 0, size: cashFlowDefaults.defaultCashFlowPageSize } }));
  }, [client, dispatch]);

  const handleDisplayCashFlowPage = () => {
    setShowModalPage(!showModalPage);
  };

  const showData = !cashFlowLoading && !cashFlowError && cashFlowCapCalls;
  const isLoading = (cashFlowLoading || !cashFlowCapCalls) && !cashFlowError;

  return (
    <Stack>
      {isLoading ? (
        <CashFlowWidgetSkeleton />
      ) : (
        <>
          <CardHeader
            sx={cardHeaderPropsDefault}
            avatar={
              <Avatar sx={(theme) => ({ backgroundColor: theme.palette.primary.main })} aria-label="recipe">
                <TextSnippetIcon sx={(theme) => ({ color: theme.palette.primary.contrastText })} />
              </Avatar>
            }
            action={
              <Button
                variant="text"
                disabled={!showData || (cashFlowCapCalls.length === 0 && !filtersApplied)}
                onClick={handleDisplayCashFlowPage}
              >
                {locale.actions.view}
              </Button>
            }
            title={
              <Typography variant="h6" color="textPrimary">
                {locale.title}
              </Typography>
            }
          />
          {showData && cashFlowCapCalls && (
            <HorizontalSwiper
              items={cashFlowCapCalls.map((capCall, i) => (
                <CashFlowCard key={"cc" + i} capCall={capCall} />
              ))}
            />
          )}

          {showData && cashFlowCapCalls.length === 0 && <NoDataResult text={locale.no_data} />}
          {!cashFlowLoading && cashFlowError && <DataLoadingFailed bgColor="none" sx={{ px: 2 }} />}
        </>
      )}
      {!cashFlowError && showModalPage && <CashFlowsModalPage onClose={handleDisplayCashFlowPage} />}
    </Stack>
  );
};

export default CashFlowsWidgetMobile;
