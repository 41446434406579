import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Container, Stack, Tab, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../shared/components/DataLoadingFailed";
import useFetch from "../../../shared/hooks/useFetch";
import { logError } from "../../../shared/logging";
import { formatDateOnly } from "../../../shared/utilities/dateUtils";
import { api } from "../../api/client";
import DataSubmissionsFilterPanel from "./DataSubmissionsFilterPanel";
import {
  DataSubmissionsFilterState,
  getInitialDataSubmissionsFilterState,
  TabValue,
  tabValues,
} from "./dataSubmissionsFilterState";
import DataSubmissionsGrid from "./DataSubmissionsGrid";

const DataCollectionPage = () => {
  const [filterState, setFilterState] = useState<DataSubmissionsFilterState>(getInitialDataSubmissionsFilterState());

  const handleTabChange = (_: React.SyntheticEvent, selectedTab: TabValue) => {
    setFilterState({ ...getInitialDataSubmissionsFilterState(), selectedTab });
  };

  const getDataSubmissions = useCallback(
    () =>
      api.dataCollection.searchDataSubmissions({
        statuses: filterState.selectedTab === "active" ? ["Pending"] : ["Completed"],
        searchTerm: filterState.searchValue.trim().toLowerCase() || undefined,
        requestTemplateIds: filterState.selectedTemplateIds.length > 0 ? filterState.selectedTemplateIds : undefined,
        reportingDateFrom: filterState.reportingDateRange?.from
          ? formatDateOnly(filterState.reportingDateRange.from)
          : undefined,
        reportingDateTo: filterState.reportingDateRange?.to
          ? formatDateOnly(filterState.reportingDateRange.to)
          : undefined,
      }),
    [
      filterState.reportingDateRange?.from,
      filterState.reportingDateRange?.to,
      filterState.searchValue,
      filterState.selectedTab,
      filterState.selectedTemplateIds,
    ]
  );

  const [dataSubmissions, fetchSubmissionsError, { isFetching: isFetchingSubmissions }] = useFetch(getDataSubmissions);

  const [filterOptions, filterOptionsError, { isFetching: isFetchingFilterOptions }] = useFetch(
    api.dataCollection.getDataSubmissionFilterOptions
  );

  const fetchError = fetchSubmissionsError || filterOptionsError;
  const isFetching = isFetchingSubmissions || isFetchingFilterOptions;

  if (fetchError) {
    logError(fetchError, "[DataCollectionPage] searchDataSubmissions");
    return <DataLoadingFailed title="Could not load data submissions" />;
  }

  const rows = isFetching ? [] : (dataSubmissions ?? []);

  const hiddenColumns = filterState.selectedTab === "active" ? ["submittedBy", "submittedAt"] : ["dueDate"];

  const noRowsText =
    filterState.selectedTab === "active"
      ? "You do not have active submissions to fill out."
      : "You do not have completed submissions.";

  return (
    <Container maxWidth={false} sx={{ height: "100%" }}>
      <Stack spacing={2} pt={2} pb={1} px={2} height="100%">
        <Typography variant="h4">Data Collection</Typography>

        <TabContext value={filterState.selectedTab}>
          <TabList onChange={handleTabChange}>
            <Tab value="active" label="Active" />
            <Tab value="completed" label="Completed" />
          </TabList>

          {tabValues.map((tabValue) => (
            <TabPanel key={tabValue} value={tabValue} sx={{ height: "100%", pt: 0.5 }}>
              <DataSubmissionsFilterPanel
                recordCount={rows.length}
                filterState={filterState}
                setFilterState={setFilterState}
                isLoading={isFetching}
                filterOptions={filterOptions ?? { requestTemplateIds: [] }}
              />
              <DataSubmissionsGrid
                rows={rows}
                hiddenColumns={hiddenColumns}
                isLoading={isFetching}
                noRowsText={noRowsText}
              />
            </TabPanel>
          ))}
        </TabContext>
      </Stack>
    </Container>
  );
};

export default DataCollectionPage;
