import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import PersonIcon from "@mui/icons-material/PersonOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { Box, Divider, Menu } from "@mui/material";
import { useSelector } from "react-redux";
import { useClientContext } from "../../contexts/ClientContext";
import { pageRoutes } from "../../routes";
import { impersonationSelector, investorPermissionsSelector } from "../../store/state/user/selectors";
import UserMenuItem from "./UserMenuItem";
import UserComponent from "./user/UserComponent";

interface Props {
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

export const UserMenu = (props: Props) => {
  const { anchorEl, onClose } = props;
  const { isFeatureEnabled } = useClientContext();

  const isImpersonation = useSelector(impersonationSelector);
  const investorPermissions = useSelector(investorPermissionsSelector);

  const getInvestorSettingsRoute = () => {
    if (isFeatureEnabled("AccountSettingsViewer")) {
      return `${pageRoutes.settings}/lp/account`;
    }
    if (isFeatureEnabled("BankAccountsDetailsViewer")) {
      return `${pageRoutes.settings}/lp/bank`;
    }
    if (isFeatureEnabled("Membership")) {
      return `${pageRoutes.settings}/lp/members`;
    }
    return undefined;
  };

  const investorSettingsRoute = getInvestorSettingsRoute();

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      onClick={onClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: 40, vertical: 50 }}
      slotProps={{ paper: { sx: { width: "18rem" } } }}
    >
      <Box mt={1} mb={2} mx={2}>
        <UserComponent />
      </Box>
      <Divider sx={{ m: 1 }} />
      <UserMenuItem text="My Profile" page={`${pageRoutes.settings}/lp/profile`} Icon={PersonIcon} />
      {investorPermissions.length > 0 && investorSettingsRoute && (
        <UserMenuItem text="Investor Settings" page={investorSettingsRoute} Icon={SettingsIcon} />
      )}
      <Divider sx={{ m: 1 }} />
      <UserMenuItem text="Log out" page="logout" Icon={LogoutIcon} disabled={isImpersonation} />
    </Menu>
  );
};

export default UserMenu;
