import { Stack, Typography } from "@mui/material";
import TypographyMultilineEllipsis from "../../../../shared/components/TypographyMultilineEllipsis";
import { CardSubheaderProps } from "../balance/mobile/types";

export const CardSubHeader = ({ title, subtitle, titleVariant, bgColor }: CardSubheaderProps) => {
  return (
    <Stack pt={1} spacing={0.5}>
      <TypographyMultilineEllipsis
        maxLines={2}
        typographyProps={{
          variant: titleVariant,
          sx: {
            bgcolor: bgColor,
            color: "text.primary",
          },
        }}
      >
        {title}
      </TypographyMultilineEllipsis>
      {subtitle && (
        <Typography color="text.secondary" variant="caption">
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
};
