import { alpha, Box } from "@mui/material";
import { TreeItem } from "@mui/x-tree-view";
import TypographyTooltipEllipsis from "../../../../../shared/components/TypographyTooltipEllipsis";
import { IItem } from "../../../../store/state/inboxStructure/types";
import InboxBadge from "../../../common/InboxBadge";
import DynamicIcon from "../DynamicIcon";

interface TreeItemLabelProps {
  item: IItem;
  onClick: () => void;
}

const TreeItemLabel = ({ item, onClick }: TreeItemLabelProps) => {
  return (
    <Box display="flex" alignItems="center" color="text.secondary" height="2.5rem">
      <DynamicIcon color="success" iconName={item.caption} />
      <Box display="flex" alignItems="center" flex={1} position="relative" onClick={onClick}>
        <Box position="absolute" width="100%" height="100%" display="flex" alignItems="center">
          <TypographyTooltipEllipsis text={item.caption} typographyProps={{ variant: "subtitle2", pl: 1, mr: 4 }} />
        </Box>
      </Box>
      <InboxBadge value={item.unread || 0} mr={1.2} />
    </Box>
  );
};

interface Props {
  inboxStructure: IItem[];
  onItemSelected: (item: string) => void;
}

const InvestorTree = ({ inboxStructure, onItemSelected }: Props) => {
  const buildInvestors = (parentCaption: string, structure: IItem[]): JSX.Element[] =>
    structure.map((item) => {
      const id = parentCaption ? `${parentCaption}/${item.caption}` : item.caption;
      return (
        <TreeItem
          key={item.caption}
          itemId={id}
          label={<TreeItemLabel item={item} onClick={() => onItemSelected(id)} />}
          sx={(t) => ({
            "& .MuiTreeItem-content": {
              py: 0,
              "&.Mui-selected": {
                bgcolor: alpha(t.palette.primary.main, 0.2),
              },
            },
          })}
        >
          {buildInvestors(item.caption, item.childTags ?? [])}
        </TreeItem>
      );
    });

  return <>{buildInvestors("", inboxStructure)}</>;
};

export default InvestorTree;
