import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useInboxStateContext } from "../../../../../contexts/InboxStateContext";
import { filterCategoriesSelector } from "../../../../../store/state/messages/selectors";
import FilterAutocomplete from "../../../../common/filters/FilterAutocomplete";

const DocumentSelect = () => {
  const { updateCriteria } = useInboxStateContext();

  const categories = useSelector(filterCategoriesSelector);
  const { criteria } = useInboxStateContext();

  const updateDocuments = useCallback(
    (values: string[]) => {
      const allCategories = categories || [];
      updateCriteria({ categories: allCategories.filter((c) => values.includes(c.value)).map((k) => k.key), page: 0 });
    },
    [updateCriteria, categories]
  );

  return (
    <FilterAutocomplete
      values={(categories || []).map((c) => c.value)}
      selectedValues={(categories || []).filter((c) => criteria.categories?.includes(c.key)).map((k) => k.value) || []}
      onSelected={updateDocuments}
      Icon={DescriptionOutlinedIcon}
      placeholder="Document"
    />
  );
};

export default DocumentSelect;
