import { Box, Divider } from "@mui/material";
import { Outlet } from "react-router";
import InboxTreeComponent from "../inboxTree/InboxTreeComponent";

export const InboxComponent = () => {
  return (
    <Box display="flex" width="100%" flex={1}>
      <Box display="flex" width="19rem">
        <InboxTreeComponent />
      </Box>
      <Divider orientation="vertical" />
      <Box display="flex" width="100%" flex={1}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default InboxComponent;
