import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useScrollEvent } from "../../../shared/hooks/useScrollEvent";
import { useClientContext } from "../../contexts/ClientContext";
import DefaultBannerImage from "../../images/default_banner.png";
import { identityNameSelector } from "../../store/state/user/selectors";

const Banner = () => {
  const { portalSettings, clientTitle } = useClientContext();
  const userName = useSelector(identityNameSelector);
  const pageOffset = useScrollEvent({ sensitivity: 10 });
  const [shrinkBanner, setShrinkBanner] = useState(false);
  const isTabletOrLower = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const theme = useTheme();

  useEffect(() => {
    if (shrinkBanner && pageOffset < 20) {
      setShrinkBanner(false);
    }
    if (!shrinkBanner && pageOffset > 100) {
      setShrinkBanner(true);
    }
  }, [pageOffset, shrinkBanner]);

  if (isTabletOrLower) {
    return null;
  }

  const imageSrc = portalSettings?.bannerUrl || DefaultBannerImage;
  const bannerFontColor =
    portalSettings?.bannerFontColor || theme.palette.primary.contrastText || theme.palette.text.primary;

  return (
    <Box
      sx={(theme) => ({
        position: "sticky",
        top: theme.spacing(8),
        left: 0,
        py: theme.spacing(shrinkBanner ? 2 : 3),
        px: theme.spacing(5),
        width: "100%",
        backgroundImage: `url(${imageSrc})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: theme.palette.common.white,
        transition: "0.2s",
        zIndex: 999,
        maxHeight: "120px",
      })}
    >
      <Typography variant={shrinkBanner ? "h4" : "h3"} color={bannerFontColor}>
        {userName}
      </Typography>
      <Typography fontWeight={500} variant={shrinkBanner ? "h6" : "h5"} color={bannerFontColor}>
        {`Welcome to the ${clientTitle} Investor Portal`}
      </Typography>
    </Box>
  );
};

export default Banner;
