import { Theme, useMediaQuery } from "@mui/material";
import { Navigate, Outlet, Route, Routes } from "react-router";
import DashboardPage from "./components/dashboard/DashboardPage";
import DashboardPageMobile from "./components/dashboard/DashboardPageMobile";
import CashFlowsPage from "./components/dashboard/cashFlows/CashFlowsPage";
import CashFlowsMobilePage from "./components/dashboard/cashFlows/mobile/CashFlowsMobilePage";
import DataCollectionPage from "./components/data-collection/DataCollectionPage";
import DataSubmissionPage from "./components/data-collection/submissions/DataSubmissionPage";
import FundraisingListPage from "./components/fundraising/FundraisingListPage";
import FundraisingPage from "./components/fundraising/FundraisingPage";
import AttachmentPreviewRedirect from "./components/inbox/AttachmentPreviewRedirect";
import InboxContainer from "./components/inbox/InboxContainer";
import InboxComponent from "./components/inbox/desktop/InboxComponent";
import Messages from "./components/inbox/desktop/Messages";
import { MessageComponent } from "./components/inbox/message/MessageComponent";
import MessagesListComponent from "./components/inbox/messagesList/mobile/MessagesListComponent";
import Settings from "./components/settings/Settings";
import SettingsPage from "./components/settings/SettingsPage";
import AccountSettings from "./components/settings/lp/account/AccountSettings";
import BankSettings from "./components/settings/lp/bank/BankSettings";
import ChangeFundAssignmentsPage from "./components/settings/lp/bank/changeFundAssignments/ChangeFundAssignmentsPage";
import NewBankAccountPage from "./components/settings/lp/bank/newBankAccount/NewBankAccountPage";
import MembersSettings from "./components/settings/lp/members/MembersSettings";
import PermissionsComponent from "./components/settings/lp/members/permissions/PermissionsComponent";
import MyProfile from "./components/settings/profile/myProfile/MyProfile";
import PasswordAuth from "./components/settings/profile/passwordAuth/PasswordAuth";
import { useClientContext } from "./contexts/ClientContext";
import { pageRoutes } from "./routes";

const Pages = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between("sm", "lg"));

  const { clientCode, isFeatureEnabled } = useClientContext();

  const getHomeRoute = () => {
    if (isFeatureEnabled("Dashboards")) {
      return `/${clientCode}/${pageRoutes.dashboard}`;
    }
    if (isFeatureEnabled("Inbox")) {
      return `/${clientCode}/${pageRoutes.inbox}`;
    }
    if (isFeatureEnabled("Fundraising")) {
      return `/${clientCode}/${pageRoutes.fundraising}`;
    }
    if (isFeatureEnabled("DataCollection")) {
      return `/${clientCode}/${pageRoutes.dataCollection}`;
    }
    return `/${clientCode}/${pageRoutes.settings}/lp/profile`;
  };

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={getHomeRoute()} />} />

      <Route path={pageRoutes.settings} element={<SettingsPage />}>
        <Route path="lp" element={<Settings />}>
          <Route path="profile" element={<MyProfile />} />
          <Route path="password-auth" element={<PasswordAuth />} />
          {isFeatureEnabled("AccountSettingsViewer") && <Route path="account" element={<AccountSettings />} />}
          {isFeatureEnabled("BankAccountsDetailsViewer") && <Route path="bank" element={<BankSettings />} />}
          {isFeatureEnabled("Membership") && <Route path="members" element={<MembersSettings />} />}
        </Route>
        {isFeatureEnabled("BankAccountsDetailsEditor") && <Route path="lp/bank/new" element={<NewBankAccountPage />} />}
        {isFeatureEnabled("BankAccountsDetailsEditor") && (
          <Route path="lp/bank/assign" element={<ChangeFundAssignmentsPage />} />
        )}
        {isFeatureEnabled("Membership") && (
          <Route path="lp/members/:investorId/permissions" element={<PermissionsComponent />} />
        )}
      </Route>

      {isFeatureEnabled("Dashboards") && isMobile && (
        <Route path={pageRoutes.dashboard} element={<DashboardPageMobile />} />
      )}
      {isFeatureEnabled("Dashboards") && !isMobile && <Route path={pageRoutes.dashboard} element={<DashboardPage />} />}
      {isFeatureEnabled("Dashboards") && isMobile && (
        <Route path={`${pageRoutes.dashboard}/cash-flows`} element={<CashFlowsMobilePage />} />
      )}
      {isFeatureEnabled("Dashboards") && !isMobile && (
        <Route path={`${pageRoutes.dashboard}/cash-flows`} element={<CashFlowsPage />} />
      )}

      {isFeatureEnabled("Fundraising") && (
        <Route path={pageRoutes.fundraising} element={<Outlet />}>
          <Route index element={<FundraisingListPage />} />
          <Route path=":id" element={<FundraisingPage />} />
        </Route>
      )}

      {isFeatureEnabled("DataCollection") && (
        <Route path={pageRoutes.dataCollection} element={<Outlet />}>
          <Route index element={<DataCollectionPage />} />
          <Route path="submissions" element={<Outlet />}>
            <Route path=":id" element={<DataSubmissionPage />} />
          </Route>
        </Route>
      )}

      {isFeatureEnabled("Inbox") && isMobile && (
        <Route path={pageRoutes.inbox} element={<InboxContainer />}>
          <Route path=":tag" element={<MessagesListComponent />} />
          <Route path=":tag/:page" element={<MessagesListComponent />} />
          <Route path=":tag/message/:id" element={<MessageComponent />} />
        </Route>
      )}
      {isFeatureEnabled("Inbox") && !isMobile && (
        <Route path={pageRoutes.inbox} element={<InboxContainer keepPage={!isTablet} />}>
          <Route path=":tag" element={<InboxComponent />}>
            <Route index element={<Messages />} />
            <Route path=":page" element={<Messages />} />
            <Route path="message/:id" element={<MessageComponent />} />
          </Route>
        </Route>
      )}

      {isFeatureEnabled("Inbox") && (
        <Route path="preview/:externalMessageId/:externalAttachmentId" element={<AttachmentPreviewRedirect />} />
      )}

      <Route path="*" element={<Navigate replace to={getHomeRoute()} />} />
    </Routes>
  );
};

export default Pages;
