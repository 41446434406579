import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import FilterAutocomplete from "./FilterAutocomplete";

interface Props {
  disabled?: boolean;
  investors: string[];
  selectedValues: string[];
  onSelected: (values: string[]) => void;
}

const InvestorSelectFilter = ({ investors, onSelected, selectedValues, disabled }: Props) => {
  return (
    <FilterAutocomplete
      disabled={disabled}
      values={investors}
      selectedValues={selectedValues}
      onSelected={onSelected}
      Icon={PersonOutlineIcon}
      placeholder="Investor"
    />
  );
};

export default InvestorSelectFilter;
