import { Grid2 } from "@mui/material";
import { useSelector } from "react-redux";
import { useInboxStateContext } from "../../../../contexts/InboxStateContext";
import { messagesSelector } from "../../../../store/state/messages/selectors";
import Actions from "../Actions";
import NoEmailsComponent from "../noEmails/NoEmailsComponent";
import MessageGrid from "./MessageGrid";

export const MessagesListComponent = () => {
  const messages = useSelector(messagesSelector);
  const { ui, setMessageId, selection, setSelection } = useInboxStateContext();

  if (messages.length === 0 && !ui.messagesLoading) {
    return <NoEmailsComponent />;
  }

  return (
    <Grid2 width="100%" container flexDirection="column" flex={1}>
      {selection.length > 0 && (
        <Grid2
          container
          alignItems="center"
          px={0.5}
          sx={(theme) => ({ borderTop: 1, borderBottom: 1, borderColor: theme.palette.divider })}
        >
          <Grid2>
            <Actions showSelectAll selection={selection} setSelection={setSelection} />
          </Grid2>
        </Grid2>
      )}

      <Grid2 flex={1}>
        <MessageGrid selection={selection} setSelection={setSelection} navigateToMessage={setMessageId} />
      </Grid2>

      <Grid2
        sx={(theme) => ({
          bgcolor: theme.palette.grey[50],
          height: "2rem",
          borderTop: 1,
          borderColor: theme.palette.divider,
        })}
      />
    </Grid2>
  );
};
