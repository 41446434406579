import DateRangeSelect from "../../../../../../shared/components/DateRangeSelect";
import {
  anytime,
  DescriptiveDate,
  last30Days,
  lastMonth,
  lastQuarter,
  lastSevenDays,
  lastYear,
  thisMonth,
  thisQuarter,
  thisYear,
  today,
} from "../../../../../../shared/utilities/dateFilterHelper";
import { useInboxStateContext } from "../../../../../contexts/InboxStateContext";

const filterDateItems = [
  anytime,
  today,
  lastSevenDays,
  last30Days,
  thisMonth,
  thisQuarter,
  thisYear,
  lastMonth,
  lastQuarter,
  lastYear,
];

interface Props {
  fullWidth?: boolean;
}

const FilterDate = ({ fullWidth }: Props) => {
  const { updateCriteria, criteria } = useInboxStateContext();

  const onDateSelected = (descriptiveDate: DescriptiveDate) => {
    updateCriteria({
      from: descriptiveDate.from?.toISOString(),
      to: descriptiveDate.to?.toISOString(),
      descriptiveDate: descriptiveDate,
      page: 0,
    });
  };

  return (
    <DateRangeSelect
      dateRange={criteria.descriptiveDate}
      predefinedRanges={filterDateItems}
      onChange={onDateSelected}
      fullWidth={fullWidth}
    />
  );
};

export default FilterDate;
