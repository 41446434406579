import { stringComparerBy } from "../../../../../shared/utilities/arrayHelper";
import { InvestorBankAccountAssignment } from "../../../../api/types/bankAccountTypes";
import { InvestorFundPermissionSet } from "../../../../store/state/user/types";

interface FundAssignmentsRowModel extends InvestorBankAccountAssignment {
  disabled?: boolean;
}

interface RowsParams {
  fundAssignments: InvestorBankAccountAssignment[];
  availableFundInvestorIds: string[];
  fundInvestors: InvestorFundPermissionSet[];
}

export const getFundAssignmentsTableRows = ({
  fundAssignments,
  availableFundInvestorIds,
  fundInvestors,
}: RowsParams): FundAssignmentsRowModel[] => {
  const unassignedFunds: FundAssignmentsRowModel[] = fundInvestors
    .filter((fi) => !fundAssignments.some((fa) => fa.fundInvestorId === fi.fundInvestorId))
    .map(({ fundInvestorId, fundName }) => ({
      fundInvestorId,
      fundName,
      bankAccountId: "",
      bankDescription: "",
    }));

  const allFundAssignments = [...fundAssignments, ...unassignedFunds].map((fa) => ({
    ...fa,
    disabled: !availableFundInvestorIds.includes(fa.fundInvestorId),
  }));

  return allFundAssignments.sort(stringComparerBy(({ fundName }) => fundName));
};
