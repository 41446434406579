import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useInboxStateContext } from "../../../../../contexts/InboxStateContext";
import { filterInvestorsSelector } from "../../../../../store/state/messages/selectors";
import FilterAutocomplete from "../../../../common/filters/FilterAutocomplete";

export const InvestorSelect = () => {
  const { updateCriteria } = useInboxStateContext();

  const investors = useSelector(filterInvestorsSelector);
  const { criteria } = useInboxStateContext();

  const updateInvestors = useCallback(
    (values: string[]) => {
      updateCriteria({ investors: values, page: 0 });
    },
    [updateCriteria]
  );

  return (
    <FilterAutocomplete
      values={investors || []}
      selectedValues={criteria.investors || []}
      onSelected={updateInvestors}
      Icon={PersonOutlineIcon}
      placeholder="Investor"
    />
  );
};

export default InvestorSelect;
