import { SvgIconComponent } from "@mui/icons-material";
import { Checkbox, InputAdornment, ListItemText, MenuItem, Select } from "@mui/material";

interface Props {
  values: string[];
  selectedValues: string[];
  onSelected: (values: string[]) => void;
  Icon: SvgIconComponent;
  placeholder: string;
  disabled?: boolean;
}

const FilterAutocomplete = ({ values, selectedValues, onSelected, Icon, placeholder, disabled }: Props) => {
  return (
    <Select
      disabled={disabled}
      sx={(theme) => ({
        display: "flex",
        flex: 1,
        color: theme.palette.secondary.main,
        ".MuiInputBase-input": {
          color: selectedValues.length > 0 ? "text.primary" : "text.secondary",
        },
      })}
      autoFocus={false}
      multiple
      value={selectedValues}
      onChange={(event) => onSelected(event.target.value as string[])}
      displayEmpty
      startAdornment={
        <InputAdornment position="start">
          <Icon color={disabled ? "disabled" : "action"} />
        </InputAdornment>
      }
      renderValue={(selected) => {
        if (selected.length === 0) {
          return placeholder;
        }

        return selected.join(", ");
      }}
    >
      {values.map((name) => (
        <MenuItem key={name} value={name} sx={{ py: 0 }}>
          <Checkbox checked={selectedValues.indexOf(name) > -1} />
          <ListItemText sx={{ whiteSpace: "break-spaces" }} primary={name} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default FilterAutocomplete;
