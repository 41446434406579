import { Logout } from "@mui/icons-material";
import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import AssessmentIcon from "@mui/icons-material/Assessment";
import InboxIcon from "@mui/icons-material/Inbox";
import { Box, Divider, Drawer, List } from "@mui/material";
import { useSelector } from "react-redux";
import { useMatch } from "react-router";
import HorizontalFill from "../../../../shared/components/HorizontalFill";
import { useClientContext } from "../../../contexts/ClientContext";
import { pageRoutes } from "../../../routes";
import { impersonationSelector } from "../../../store/state/user/selectors";
import FeatureBoundary from "../../common/FeatureBoundary";
import SupportInfo from "../SupportInfo";
import UserComponent from "../user/UserComponent";
import { MainMenuItem } from "./MainMenuItem";

interface Props {
  anchorEl: null | HTMLElement;
  onClose: () => void;
}

export const MainMenu = ({ anchorEl, onClose }: Props) => {
  const { portalSettings } = useClientContext();

  const isImpersonation = useSelector(impersonationSelector);

  const routeMatch = useMatch("/:client/:pageName/*");
  const pageName = routeMatch?.params["pageName"];

  return (
    <Drawer sx={{ ".MuiPaper-root": { width: "66.6%" } }} anchor="left" open={Boolean(anchorEl)} onClose={onClose}>
      <Box display="flex" width="100%" flexDirection="column" flex={1} py={2}>
        <Box sx={(t) => ({ backgroundColor: t.palette.background.grey, p: 2, mx: 2, borderRadius: 1 })}>
          <UserComponent />
        </Box>

        <Divider sx={{ py: 1 }} />

        <List>
          <FeatureBoundary required="Inbox">
            <MainMenuItem
              text="Inbox"
              page={`${pageRoutes.inbox}/Inbox/p0`}
              isActive={pageName === "inbox"}
              Icon={InboxIcon}
              onClick={onClose}
            />
          </FeatureBoundary>
          <FeatureBoundary required="Fundraising">
            <MainMenuItem
              text="Fundraising"
              page={pageRoutes.fundraising}
              Icon={ArticleIcon}
              onClick={onClose}
              isActive={pageName === "fundraising"}
            />
          </FeatureBoundary>
          <FeatureBoundary required="Dashboards">
            <MainMenuItem
              text="Dashboard"
              page={pageRoutes.dashboard}
              Icon={AssessmentIcon}
              onClick={onClose}
              isActive={pageName === "dashboard"}
            />
          </FeatureBoundary>
        </List>

        <HorizontalFill />

        <List>
          <MainMenuItem
            text="Log out"
            page="logout"
            Icon={Logout}
            color="primary"
            onClick={onClose}
            disabled={isImpersonation}
          />
        </List>

        <Divider />

        <Box p={2}>
          <SupportInfo isMobile email={portalSettings?.supportEmail} phone={portalSettings?.supportPhone} />
        </Box>
      </Box>
    </Drawer>
  );
};

export default MainMenu;
