import { SvgIconComponent } from "@mui/icons-material";
import { ListItemButton, ListItemIcon, ListItemText, SvgIconOwnProps } from "@mui/material";
import { Link } from "react-router";
import { useClientContext } from "../../../contexts/ClientContext";

interface Props {
  text: string;
  page: string;
  Icon: SvgIconComponent;
  color?: "primary";
  disabled?: boolean;
  onClick: () => void;
  isActive?: boolean;
}

const colorMap: Record<
  string,
  {
    textColor: string;
    iconColor: SvgIconOwnProps["color"];
  }
> = {
  primary: {
    textColor: "primary.main",
    iconColor: "primary",
  },
};

export const MainMenuItem = ({ text, page, Icon, color, disabled, onClick, isActive }: Props) => {
  const { clientCode } = useClientContext();
  const colorMapResult = colorMap[color ?? ""];

  return (
    <ListItemButton
      selected={isActive}
      disabled={disabled}
      component={Link}
      to={`/${clientCode}/${page}`}
      sx={(theme) => ({ py: theme.spacing(1.5), pl: theme.spacing(3) })}
      onClick={onClick}
    >
      <ListItemIcon sx={(theme) => ({ minWidth: theme.spacing(4) })}>
        <Icon color={colorMapResult?.iconColor || "secondary"} />
      </ListItemIcon>
      <ListItemText
        slotProps={{
          primary: {
            sx: { color: isActive ? "primary.main" : colorMapResult?.textColor || "secondary.main" },
            variant: "subtitle2",
          },
        }}
      >
        {text}
      </ListItemText>
    </ListItemButton>
  );
};
