import { handleRedirectUrlState } from "../../utilities/handleRedirectUrlState";
import { getSharedVariables } from "../../variables";
import { RedirectKeys } from "../authTypes";
import { getAuthSettings } from "../settings";

export const authState: Record<string, string | boolean | undefined> = {};

const authFlowParams = ["login", "register", "passwordreset"] as const;
type AuthFlow = (typeof authFlowParams)[number];

export function redirectToPasswordReset() {
  const userFlowParam = getUserFlowParam("passwordreset");
  if (!userFlowParam) {
    return;
  }

  authState["userFlow"] = userFlowParam;

  const currentUrl = new URL(window.location.href);
  authState["originalPath"] = `${currentUrl.pathname}${currentUrl.search}`;
  authState["origin"] = currentUrl.origin;

  const redirectUrl = getAuthURL(userFlowParam);
  window.location.href = redirectUrl.toString();
}

export function redirectToB2CAuth() {
  const userFlowParam = getUserFlowParam("login");
  if (!userFlowParam) {
    return;
  }

  authState["userFlow"] = userFlowParam;

  const currentUrl = new URL(window.location.href);
  authState["originalPath"] = `${currentUrl.pathname}${currentUrl.search}`;
  authState["origin"] = currentUrl.origin;

  const redirectUrl = getAuthURL(userFlowParam);
  window.location.href = redirectUrl.toString();
}

export function handleB2CRedirectUrlState(onRedirectToB2C: () => void): boolean {
  const originUrl = new URL(window.location.href);

  // If the redirect happens to the oauth (B2C), then ignore redirect - the app will handle it separately
  if (originUrl.pathname.startsWith("/oauth")) {
    return false;
  }

  const redirectParams = tryGetShortUrlRedirectParams(originUrl);

  if (redirectParams !== undefined) {
    onRedirectToB2C();
    return handleRedirectToB2C(redirectParams);
  }

  return handleRedirectUrlState();
}

interface B2CRedirectParams {
  flow: AuthFlow;
  client: string;
  email: string | null | undefined;
}

function tryGetShortUrlRedirectParams(originUrl: URL): B2CRedirectParams | undefined {
  const segments = originUrl.pathname.split("/");
  const clientParam = segments[1];
  const flowParam = segments[2];
  if (segments.length === 3 && authFlowParams.includes(flowParam as AuthFlow)) {
    return {
      client: clientParam || "entrilia",
      flow: flowParam as AuthFlow,
      email: originUrl.searchParams.get("email"),
    };
  }

  return undefined;
}

function handleRedirectToB2C({ flow, client, email }: B2CRedirectParams) {
  const userFlowParam = getUserFlowParam(flow);
  const { authEndpoint, clientId } = getAuthSettings();

  if (!userFlowParam || !client || !authEndpoint || !clientId) {
    return false;
  }

  const currentUrl = new URL(window.location.href);
  authState["origin"] = currentUrl.origin;
  authState["originalPath"] = `/${client}/`;
  authState["userFlow"] = userFlowParam;
  authState["client"] = client;

  const authUrl = getAuthURL(userFlowParam);
  if (email) {
    authUrl.searchParams.append("login_hint", email);
  }

  window.location.href = authUrl.toString();
  return true;
}

function getAuthURL(userFlowParam: string): URL {
  const redirectUri = getSharedVariables().PORTAL_REDIRECT_URI;
  const { clientId, authEndpoint, portalAccessScope } = getAuthSettings();
  const authUrl = new URL(authEndpoint);

  authUrl.searchParams.append("p", userFlowParam);
  authUrl.searchParams.append("client_id", clientId);
  authUrl.searchParams.append("nonce", "defaultNonce");
  authUrl.searchParams.append("redirect_uri", redirectUri);
  authUrl.searchParams.append("scope", `openid offline_access ${portalAccessScope}`);
  authUrl.searchParams.append("response_type", "code");
  authUrl.searchParams.append("prompt", "login");
  authUrl.searchParams.append(RedirectKeys.State, JSON.stringify(authState));

  return authUrl;
}

function getUserFlowParam(flow: AuthFlow) {
  const authSettings = getAuthSettings();
  switch (flow) {
    case "login":
      return authSettings.userFlowSignIn;
    case "register":
      return authSettings.userFlowSignUp;
    case "passwordreset":
      return authSettings.userFlowPasswordReset;
    default:
      return undefined;
  }
}
