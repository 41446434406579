import { Typography, TypographyProps } from "@mui/material";
import "/node_modules/flag-icons/css/flag-icons.min.css";

interface Props {
  countryCodeAlpha2: string;
  typographyProps?: TypographyProps;
}

const CountryFlag = ({ countryCodeAlpha2, typographyProps }: Props) => (
  <Typography
    component="span"
    width={20}
    height="auto"
    {...typographyProps}
    className={`fi fi-${countryCodeAlpha2.toLowerCase()}`}
  />
);

export default CountryFlag;
