import { DescriptiveDate } from "../../../shared/utilities/dateFilterHelper";

export const tabValues = ["active", "completed"] as const;
export type TabValue = (typeof tabValues)[number];

export interface DataSubmissionsFilterState {
  selectedTab: "active" | "completed";
  searchValue: string;
  selectedTemplateIds: string[];
  reportingDateRange: DescriptiveDate | undefined;
}

export const getInitialDataSubmissionsFilterState = (): DataSubmissionsFilterState => ({
  selectedTab: "active",
  searchValue: "",
  selectedTemplateIds: [],
  reportingDateRange: undefined,
});
