import { Box } from "@mui/material";
import Breakpoint from "../../../../shared/components/Breakpoint";
import { MessagesListComponent as MessagesListComponentDesktop } from "../messagesList/desktop/MessagesListComponent";
import { MessageListViewComponent } from "../messagesList/listView/MessageListViewComponent";
import SearchComponent from "../search/SearchComponent";

export const Messages = () => {
  return (
    <Box display="flex" width="100%" flex={1}>
      <Breakpoint from="sm" to="lg">
        <Box display="flex" width="100%" flexDirection="column">
          <SearchComponent />
          <MessageListViewComponent />
        </Box>
      </Breakpoint>
      <Breakpoint from="lg">
        <Box display="flex" width="100%" flexDirection="column">
          <SearchComponent />
          <MessagesListComponentDesktop />
        </Box>
      </Breakpoint>
    </Box>
  );
};

export default Messages;
