import { useState } from "react";
import ConsentAgreementDialog from "../../../shared/components/consentAgreement/ConsentAgreementDialog";
import {
  acceptAgreementAction,
  getInitialConsentAgreementsState,
} from "../../../shared/components/consentAgreement/consentAgreementsState";
import { ConsentAgreement } from "../../../shared/components/consentAgreement/types";
import { useNotificationContext } from "../../../shared/contexts/NotificationContext";
import { logError } from "../../../shared/logging";
import { api } from "../../api/client";
import { useClientContext } from "../../contexts/ClientContext";
import { AcceptedConsentAgreement } from "../../store/state/user/types";

interface Props {
  consentAgreements: ConsentAgreement[];
}

const mapToAcceptedAgreement = (consentAgreement: ConsentAgreement): AcceptedConsentAgreement => ({
  type: consentAgreement.type,
  name: consentAgreement.name,
  version: consentAgreement.version,
});

const ConsentAgreementContainer = ({ consentAgreements }: Props) => {
  const { sendNotificationError } = useNotificationContext();
  const [saving, setSaving] = useState(false);
  const { portalSettings, clientTitle } = useClientContext();
  const [state, setState] = useState(getInitialConsentAgreementsState(consentAgreements));

  const saveConsentsAndReload = async (acceptedAgreements: ConsentAgreement[]) => {
    setSaving(true);
    try {
      const resp = await api.user.updateIdentity({
        consentAgreements: acceptedAgreements.map(mapToAcceptedAgreement),
      });

      if (resp?.success) {
        window.location.reload();
      }
    } catch (err) {
      logError(err, "ConsentAgreementContainer");
      sendNotificationError("Failed to save consent");
    } finally {
      setSaving(false);
    }
  };

  const handleAgreementAccept = async (acceptedAgreement: ConsentAgreement) => {
    if (state.isLast) {
      await saveConsentsAndReload([...state.acceptedAgreements, acceptedAgreement]);
    } else {
      setState(acceptAgreementAction());
    }
  };

  if (!state.nextAgreementToAccept) {
    return null;
  }

  return (
    <ConsentAgreementDialog
      saving={saving}
      open={true}
      clientTitle={clientTitle}
      logoUrl={portalSettings?.contactInvitationEmailCompanyLogoUrl}
      consentAgreement={state.nextAgreementToAccept}
      onAccept={handleAgreementAccept}
      portalTitle={`${clientTitle} Portal`}
    />
  );
};

export default ConsentAgreementContainer;
