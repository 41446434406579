import { Box, Grid2, Stack } from "@mui/material";
import { ReactNode } from "react";
import FilterDate from "./filterDate/FilterDate";
import DocumentSelect from "./selects/DocumentSelect";
import FundTreeSelect from "./selects/FundTreeSelect";
import InvestorSelect from "./selects/InvestorSelect";

const FilterItem = (props: { children?: ReactNode | undefined }) => {
  return (
    <Grid2
      sx={{
        display: "flex",
        flex: 1,
        maxWidth: "100%",
        height: "2.5rem",
        position: "relative",
      }}
    >
      <Box sx={{ position: "absolute", width: "100%", height: "100%" }}>{props.children}</Box>
    </Grid2>
  );
};

interface Props {
  direction: "row" | "column";
}

export const FilterComponent = ({ direction }: Props) => {
  if (direction === "row") {
    return (
      <Grid2 container width="100%" gridAutoFlow="column" gap={1} maxWidth="58rem">
        <FilterItem>
          <InvestorSelect />
        </FilterItem>
        <FilterItem>
          <FundTreeSelect />
        </FilterItem>
        <FilterItem>
          <DocumentSelect />
        </FilterItem>
        <FilterItem>
          <FilterDate fullWidth />
        </FilterItem>
      </Grid2>
    );
  }

  return (
    <Stack spacing={2}>
      <InvestorSelect />
      <FundTreeSelect />
      <DocumentSelect />
      <FilterDate fullWidth />
    </Stack>
  );
};

export default FilterComponent;
